import { useEffect } from "react";
import { useParams } from "react-router-dom";

export function LiteRedirect() {
  const { "*": path } = useParams();

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    queryString.set("lite", "1");
    window.location.href = `/${path}?${queryString}`;
  }, [path]);

  return <></>;
}
