import { devMode } from "@src/env";
import KenBurnsElement from "ken-burns-carousel/dist/element";

import { CurrentTrack } from "@src/components/CurrentTrack";
import { Debug } from "@src/components/Debug";
import { ErrorMessage } from "@src/components/ErrorMessage";
import { NextTrack } from "@src/components/NextTrack";
import { OfflineModeIndicator } from "@src/components/OfflineModeIndicator";
import { PlayPause } from "@src/components/PlayPause";
import { ServiceWorker } from "@src/components/ServiceWorker";
import { SocialMedia } from "@src/components/SocialMedia";

import { usePlayer } from "@src/hooks/usePlayer";

import { shuffleArray } from "@src/utils/shuffleArray";

import "./style.less";

customElements.define("ken-burns-carousel", KenBurnsElement);

const images = shuffleArray(
  Array.from(
    { length: 17 },
    (_, i) => `/canopy/img_${String(i + 1).padStart(2, "0")}.jpg`,
  ),
);

export function CanopyPlayer() {
  const { radio } = usePlayer();

  if (!radio) {
    return <></>;
  }

  return (
    <div className="CanopyPlayer-page">
      <ServiceWorker radio={radio} />
      <main>
        <ken-burns-carousel
          images={images}
          fade-duration={2500}
          slide-duration={8000}
        />
        <OfflineModeIndicator radio={radio} />
        <SocialMedia />
        {devMode && <Debug radio={radio} />}
      </main>
      <footer>
        <span className="radio_name">Rádio Canopy By Hilton</span>
        <PlayPause color="#fff" fontSize="4.5em" size={50} shadow={false} />
        <ErrorMessage />
        <CurrentTrack />
        <NextTrack />
      </footer>
    </div>
  );
}
