import clsx from "clsx";
import { type InputHTMLAttributes, forwardRef } from "react";

import "./style.less";

export const Select = forwardRef<
  HTMLSelectElement,
  InputHTMLAttributes<HTMLSelectElement>
>(function Select({ className, ...props }, ref) {
  return (
    <select
      {...props}
      ref={ref}
      className={clsx("Select-component", className)}
    />
  );
});
