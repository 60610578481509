export async function streamToBlob(stream: ReadableStream<Uint8Array>) {
  const reader = stream.getReader();
  const chunks: Uint8Array[] = [];
  let done, value;
  while (!done) {
    ({ done, value } = await reader.read());
    if (!done) {
      chunks.push(value!);
    }
  }
  return new Blob(chunks);
}
