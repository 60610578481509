import { type EffectCallback, useLayoutEffect, useState } from "react";

export function useMount(effect: EffectCallback) {
  const [didMount, setDidMount] = useState(false);
  useLayoutEffect(() => {
    if (didMount) {
      return effect();
    }
    setDidMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount]);
}

// useMount(() => {
//   console.log("The component is mounted");
//   return () => console.log("The component unmounts");
// });
