import clsx from "clsx";
import { type ReactNode, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaBell, FaChevronLeft } from "react-icons/fa";

import { Modal } from "@src/components/Modal";

import { useMount } from "@src/hooks/useMount";

import {
  type TNotification,
  initNotificationService,
} from "@src/services/notification";

import { formatDate } from "@src/utils/formatDate";
import { removeHtml } from "@src/utils/removeHtml";

import "./style.less";
import type { NotificationProps } from "./types";

export function Notification({ radio }: NotificationProps) {
  const [status, setStatus] = useState<
    "loading" | "connected" | "disconnected"
  >("loading");
  const [list, setList] = useState<TNotification[]>([]);
  const [notification, setNotification] = useState<TNotification | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useMount(() => {
    const endNotificationService = initNotificationService(
      radio,
      (notification) => {
        // Adiciona a notificação na lista
        setList((prev) => [notification, ...prev]);

        // Exibe a notificação
        setNotification(notification);
      },
      () => setStatus("connected"),
      () => setStatus("disconnected"),
      () => setStatus("loading"),
    );
    return () => {
      endNotificationService();
    };
  });

  useEffect(() => {
    if (!notification) return;

    // Abre a modal ao chegar uma notificação
    if (!isOpen) setIsOpen(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  let renderList: ReactNode = null;
  if (list.length === 0) {
    renderList = <p>Nenhuma notificação por enquanto.</p>;
  } else {
    renderList = list.map((item) => (
      <div
        key={item.id}
        className="preview clickable"
        onClick={() => setNotification(item)}
      >
        <img src={item.imageUrl || "/logo_white.png"} />
        <div className="column">
          <div className="text">{removeHtml(item.message)}</div>
          <div className="date">{formatDate(item.dateTime)}</div>
        </div>
      </div>
    ));
  }

  return (
    <>
      <IconContext.Provider
        value={{ color: "#ffffff", className: "icon", size: "1.5em" }}
      >
        <div
          className="Notification-component clickable"
          onClick={() => setIsOpen(true)}
          data-tooltip-id="footer"
          data-tooltip-content="Notificações"
          data-tooltip-place="top"
        >
          <FaBell />
          <div className={clsx("bolinha", status)} />
        </div>
      </IconContext.Provider>
      <Modal
        className="Notification-modal"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        {notification ? (
          <>
            <h2 className="clickable" onClick={() => setNotification(null)}>
              <FaChevronLeft /> Notificação
            </h2>
            <hr />
            <div className="container selected">
              {notification.imageUrl && <img src={notification.imageUrl} />}
              <div
                className="ck-content"
                dangerouslySetInnerHTML={{ __html: notification.message }}
              />
              <div className="date">{formatDate(notification.dateTime)}</div>
            </div>
          </>
        ) : (
          <>
            <h2>Notificações</h2>
            <hr />
            <div className="container">
              <div className="list">{renderList}</div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
