import { useContactMutation } from "@src/data/mutations/contact";
import { useState } from "react";
import { IconContext } from "react-icons";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { toast } from "react-toastify";

import { Button } from "@src/components/Button";
import { FormGroup } from "@src/components/FormGroup";
import { Input } from "@src/components/Input";
import { Loader } from "@src/components/Loader";
import { Modal } from "@src/components/Modal";
import { Textarea } from "@src/components/Textarea";

import "./style.less";
import type { ContactFormProps } from "./types";

export function ContactForm({ radio }: ContactFormProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const contact = useContactMutation();
  const loading = contact.isPending;

  return (
    <>
      <IconContext.Provider
        value={{ color: "#ffffff", className: "icon", size: "1.5em" }}
      >
        <div
          className="ContactForm-component clickable"
          onClick={() => setIsOpen(true)}
          data-tooltip-id="footer"
          data-tooltip-content="Fale conosco"
          data-tooltip-place="top"
        >
          <FaEnvelopeOpenText />
        </div>
      </IconContext.Provider>
      <Modal
        className="ContactForm-modal"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <h2>Fale conosco</h2>
        <hr />
        <form
          onSubmit={(event) => {
            event.preventDefault();
            contact.mutateAsync({ radio, name, email, phone, message }).then(
              () => {
                contact.reset(); // contact.isPending = false;
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");

                toast.success(
                  "Formulário enviado com sucesso. Aguarde nosso retorno.",
                );
                setIsOpen(false);
              },
              (error) => {
                console.log(
                  "[contact] Falha ao enviar formulário de contato:",
                  error,
                );
                toast.error("Falha ao enviar formulário.");
              },
            );
          }}
        >
          <fieldset disabled={loading}>
            <FormGroup>
              <label htmlFor="nome">Nome</label>
              <Input
                id="nome"
                type="text"
                name="nome"
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
                autoFocus
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="email">E-mail</label>
              <Input
                id="email"
                type="email"
                name="email"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="phone">Telefone</label>
              <Input
                id="phone"
                type="text"
                name="phone"
                required
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="message">Mensagem</label>
              <Textarea
                id="message"
                name="message"
                required
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </FormGroup>

            <Button type="submit" disabled={loading}>
              Enviar
            </Button>
            {contact.isError && (
              <span className="error">{contact.error.message}</span>
            )}
            <Loader visible={loading} />
          </fieldset>
        </form>
      </Modal>
    </>
  );
}
