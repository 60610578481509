import { useOfflineModeState } from "@src/states/offline_mode";

import { getRandom } from "@src/utils/getRandom";

export function getNextOfflineUrl() {
  const { cachedUrls, playedUrls } = useOfflineModeState.getState();
  const _cachedUrls = Array.from(cachedUrls);
  const _playedUrls = Array.from(playedUrls);

  const unplayedUrls = _cachedUrls.filter((url) => !_playedUrls.includes(url));
  if (_cachedUrls.length > 0 && unplayedUrls.length === 0) {
    // -2 because the last played URL (-1) is the current track
    const randomPlayed = getRandom(0, _playedUrls.length - 2);
    return _playedUrls[randomPlayed];
  }
  const randomUnplayed = getRandom(0, unplayedUrls.length - 1);
  return unplayedUrls[randomUnplayed];
}
