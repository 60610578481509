import clsx from "clsx";
import { type InputHTMLAttributes, forwardRef } from "react";

import "./style.less";

export const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(function Input({ className, ...props }, ref) {
  return (
    <input
      {...props}
      ref={ref}
      className={clsx("Input-component", className)}
    />
  );
});
