import { env } from "@src/env";
import type { Radio } from "@src/types";
import Echo from "laravel-echo";

export type TNotification = {
  id: number;
  dateTime: Date;
  message: string;
  imageUrl?: string;
};

type EchoEvent = {
  channel: string;
  message: {
    id: number;
    radio_id: number;
    message: string;
    file?: string;
    /** 2024-11-13 */
    message_sent_date: string;
  };
};

/**
 * Requer `<script src="/socket-io-slim.js"></script>`
 * no index.html para que `window.io` esteja atribuído.
 */
export function initNotificationService(
  radio: Radio,
  onNotification: (notification: TNotification) => void,
  onConnect: () => void,
  onDisconnect: () => void,
  onReconnecting: () => void,
) {
  if (typeof window.io === "undefined") {
    console.error("Socket.io not found. Check if socket-io-slim.js is loaded.");
    return () => {};
  }

  console.log("Laravel Echo Server host:", env.host);
  const echo = new Echo({
    broadcaster: "socket.io",
    host: env.host,
  });
  echo.connector.socket.on("connect", onConnect);
  echo.connector.socket.on("disconnect", onDisconnect);
  echo.connector.socket.on("reconnecting", onReconnecting);

  function onMessage(event: EchoEvent) {
    onNotification({
      id: event.message.id,
      dateTime: new Date(),
      message: event.message.message,
      imageUrl: event.message.file,
    });
  }
  const channelName = `radiomessage_${radio.folder}`;
  const channel = echo.channel(channelName).listen("SendMessage", onMessage);

  return () => {
    channel.stopListening("SendMessage", onMessage);
    echo.leaveChannel(channelName);
    echo.disconnect();
  };
}
