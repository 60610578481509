import { env } from "@src/env";
import type { Radio } from "@src/types";
import { useMutation } from "@tanstack/react-query";

type ContactForm = {
  radio: Radio;
  name: string;
  email: string;
  phone: string;
  message: string;
};

export function useContactMutation() {
  return useMutation({
    mutationKey: ["contact"],
    mutationFn: async ({ radio, name, email, phone, message }: ContactForm) => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("X-Requested-With", "XMLHttpRequest");

      return fetch(`${env.host}/api/support`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          radio_id: radio.id,
          name,
          department: "-",
          subject: "-",
          email,
          phone_number: phone,
          message,
        }),
      });
    },
  });
}
