export class Registry<T> {
  #values: Record<string, T> = {};

  set(key: string, value: T): void {
    this.#values[key] = value;
  }

  get(key: string): T | undefined {
    return this.#values[key];
  }

  remove(key: string): void {
    delete this.#values[key];
  }

  factory(key: string, createFn: () => T): T {
    if (!(key in this.#values)) {
      this.#values[key] = createFn();
    }
    return this.#values[key];
  }
}
