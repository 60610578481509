import Bowser from "bowser";

const bowser = Bowser.parse(window.navigator.userAgent);
const supported: string[] = [
  "Chrome",
  "Chromium",
  "Firefox",
  "Microsoft Edge",
  "Safari",
];
export const browserName = bowser.browser.name || "";
export const isSupported = supported.includes(browserName);
