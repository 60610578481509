import clsx from "clsx";

import "./style.less";

export function Button({
  className,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return <button className={clsx("Button-component", className)} {...props} />;
}
