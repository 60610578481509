import { env } from "@src/env";
import type {
  HistoryTrackInfo,
  RequestableTrackInfo,
  TrackInfo,
} from "@src/types";

import { Api } from "@src/services/api";

import { getSlug } from "@src/utils/getSlug";

import type {
  CurrentTrackResponse,
  NextTrackResponse,
  RequestTrackResponse,
  RequestableTracksResponse,
  StatusResponse,
  StreamInfo,
  TrackHistoryResponse,
} from "./types";

export class RadioCo {
  // #host = "https://public.radio.co";
  #host = `${env.host}/radioco`;

  #streaming_id: string;

  #eventSource: EventSource | null = null;

  #currentTrack: CurrentTrackResponse["data"] | null = null;

  #status: StatusResponse["data"] | null = null;

  /**
   * @param streaming_id Example: `sf38694d4a`
   */
  constructor(streaming_id: string) {
    this.#streaming_id = streaming_id;
  }

  getTrackInfo(
    artistTitle: string,
    artwork_small: string,
    artwork_large: string,
  ): TrackInfo {
    const split = artistTitle.split(" - ");
    const [artist, ...title] = split;
    return {
      artist,
      title: title.join(" - "),
      artwork_small,
      artwork_large,
    };
  }

  #getCurrentTrack(data: CurrentTrackResponse["data"]): TrackInfo {
    return this.getTrackInfo(
      data.title,
      data.artwork_urls.standard,
      data.artwork_urls.large,
    );
  }

  async getCurrentTrack(): Promise<TrackInfo> {
    return new Promise((resolve) => {
      if (this.#currentTrack) {
        // console.log("[getCurrentTrack] obtido com Mercure");
        resolve(this.#getCurrentTrack(this.#currentTrack));
      } else {
        // console.log("[getCurrentTrack] obtido sem Mercure");
        const url = `${this.#host}/api/v2/${this.#streaming_id}/track/current`;
        fetch(url)
          .then((response) => response.json())
          .then((response: CurrentTrackResponse) => {
            resolve(this.#getCurrentTrack(response.data));
          });
      }
    });
  }

  async getNextTrack(): Promise<TrackInfo | null> {
    return new Promise((resolve) => {
      const url = `${this.#host}/stations/${this.#streaming_id}/next`;
      fetch(url)
        .then((response) => response.json())
        .then((response: NextTrackResponse) => {
          if (!response.next_track) {
            resolve(null);
            return;
          }
          const trackInfo = this.getTrackInfo(
            response.next_track.title,
            response.next_track.artwork_url,
            response.next_track.artwork_url_large,
          );
          resolve(trackInfo);
        });
    });
  }

  async getTrackHistory(): Promise<HistoryTrackInfo[]> {
    return new Promise((resolve) => {
      const url = `${this.#host}/stations/${this.#streaming_id}/history`;
      fetch(url)
        .then((response) => response.json())
        .then((response: TrackHistoryResponse) => {
          const history: HistoryTrackInfo[] = response.tracks.map((track) => {
            const { title, artist, artwork_small } = this.getTrackInfo(
              track.title,
              track.artwork_url,
              "",
            );
            return {
              title,
              artist,
              artwork_small,
              datetime: track.start_time,
            };
          });
          history.shift();
          resolve(history);
        });
    });
  }

  #isOnAir(data: StatusResponse["data"]): boolean {
    return data.status === "onair";
  }

  async isOnAir(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.#status) {
        // console.log("[isOnAir] obtido com Mercure");
        resolve(this.#isOnAir(this.#status));
      } else {
        // console.log("[isOnAir] obtido sem Mercure");
        const url = `${this.#host}/api/v2/${this.#streaming_id}/status`;
        fetch(url)
          .then((response) => response.json())
          .then((response: StatusResponse) => {
            resolve(this.#isOnAir(response.data));
          });
      }
    });
  }

  async getRequestableTracks(): Promise<RequestableTrackInfo[]> {
    return new Promise((resolve) => {
      const url = `${this.#host}/stations/${this.#streaming_id}/requests/tracks`;
      fetch(url, { method: "GET" })
        .then((response) => response.json())
        .then((response: RequestableTracksResponse) => {
          const tracks = response.tracks.map((track) => ({
            id: track.id,
            artist: track.artist,
            title: track.title,
            artwork_small: track.artwork.url || "/logo_white.png",
            artwork_large: track.artwork.large_url || "/logo_white.png",
            keywords: getSlug(`${track.artist} ${track.title}`),
          }));
          resolve(tracks);
        });
    });
  }

  async requestTrack(track_id: number): Promise<boolean> {
    return new Promise((resolve) => {
      const url = `${this.#host}/stations/${this.#streaming_id}/requests`;
      fetch(url, {
        method: "POST",
        body: JSON.stringify({ track_id }),
        headers: {
          "Content-Type": "application/json",
          // The device fingerprint ID to associate where a request came from
          // 'X-Device': '',
        },
      })
        .then((response) => response.json())
        .then((response: RequestTrackResponse) => {
          const errors = response?.errors || [];
          resolve(errors.length === 0);
        });
    });
  }

  static async getStreamInfo(url: string): Promise<StreamInfo> {
    return new Promise((resolve) => {
      Api.getHeaders(url).then((response) => {
        const responseObj = response || {};

        let audioFormat = "";
        if ("Content-Type" in responseObj) {
          audioFormat = responseObj["Content-Type"].split("/")[1];
        }

        let channels = 0;
        let samplerate = 0;
        let bitrate = 0;
        if ("ice-audio-info" in responseObj) {
          const regex = /channels=(\d+);samplerate=(\d+);bitrate=(\d+)/;
          const match = responseObj["ice-audio-info"].match(regex);
          if (match) {
            channels = parseInt(match[1]);
            samplerate = parseInt(match[2]);
            bitrate = parseInt(match[3]);
          }
        }

        let icyName = "";
        if ("icy-name" in responseObj) {
          icyName = responseObj["icy-name"];
        }

        let server = "";
        if ("Server" in responseObj) {
          server = responseObj["Server"];
        }

        const streamInfo: StreamInfo = {
          name: icyName,
          data_source: server,
          audio_format: audioFormat as StreamInfo["audio_format"],
          channels,
          samplerate,
          bitrate,
        };
        resolve(streamInfo);
      });
    });
  }

  startEventSource() {
    if (this.#eventSource) return;

    // https://developers-84608658bd058c817.radio.co/api-reference/openapi_specs/public-v2
    const endpoint = "https://mercure.radio.co/.well-known/mercure";
    const topics = [
      `https://public.radio.co/api/v2/${this.#streaming_id}/status`,
      `https://public.radio.co/api/v2/${this.#streaming_id}/track/current`,
      // `https://public.radio.co/api/v2/${this.#streaming_id}/track/next`,
      // `https://public.radio.co/api/v2/${this.#streaming_id}/track/history`
    ];
    const params = new URLSearchParams();
    topics.forEach((topic) => params.append("topic", topic));
    params.append("Last-Event-ID", "null");
    const url = `${endpoint}?${params.toString()}`;

    this.#eventSource = new EventSource(url);
    this.#eventSource.addEventListener("open", () => {
      console.log("[EventSource] connection opened.");
    });
    this.#eventSource.addEventListener(
      `${this.#streaming_id}/status`,
      (event) => {
        if (!event.data) return;
        console.log("[EventSource] status event received.");
        const data: StatusResponse["data"] = JSON.parse(event.data);
        this.#status = data;
      },
    );
    this.#eventSource.addEventListener(
      `${this.#streaming_id}/track/current`,
      (event) => {
        if (!event.data) return;
        console.log("[EventSource] current track event received.");
        const data: CurrentTrackResponse["data"] = JSON.parse(event.data);
        this.#currentTrack = data;
      },
    );
    this.#eventSource.addEventListener("error", () => {
      console.log("[EventSource] connection lost.");
      this.stopEventSource();
    });
  }

  stopEventSource() {
    if (this.#eventSource) {
      this.#eventSource.close();
      this.#eventSource = null;
      console.log("[EventSource] connection closed.");
    }
  }
}
