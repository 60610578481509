export function alwaysResolve<T>(promise: Promise<T>): Promise<
  | {
      success: true;
      result: T;
    }
  | {
      success: false;
      result: unknown;
    }
> {
  return new Promise((resolve) => {
    try {
      promise
        .then(
          (result) => resolve({ success: true, result }),
          (result) => resolve({ success: false, result }),
        )
        .catch((result) => resolve({ success: false, result }));
    } catch (result) {
      resolve({ success: false, result });
    }
  });
}
