import { Badge } from "@src/components/Badge";
import { Button } from "@src/components/Button";

import "./style.less";
import type { LoginInfoProps } from "./types";

export function LoginInfo({ logged_in_as, logout }: LoginInfoProps) {
  return (
    <Badge className="LoginInfo-component">
      {logged_in_as}
      <Button type="button" onClick={logout}>
        Sair
      </Button>
    </Badge>
  );
}
