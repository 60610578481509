import { devMode, env } from "@src/env";
import { EncryptStorage } from "encrypt-storage";
import { createJSONStorage } from "zustand/middleware";

export const storage = new EncryptStorage(env.secretKey, {
  doNotEncryptValues: devMode,
  stateManagementUse: true,
});

export function getZustandStorage<T = string>() {
  return createJSONStorage<T>(() => ({
    getItem: (name: string) => storage.getItem(name) || null,
    setItem: (name: string, value: string) => storage.setItem(name, value),
    removeItem: (name: string) => storage.removeItem(name),
  }));
}
