import { devMode } from "@src/env";
import { ReactQueryDevtools as BaseReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ComponentProps, Suspense, lazy, useEffect, useState } from "react";

import "./style.less";

const ReactQueryDevtoolsProduction = lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

const devtoolsOptions: ComponentProps<typeof BaseReactQueryDevtools> = {
  initialIsOpen: false,
  buttonPosition: "top-right",
};

export function ReactQueryDevtools() {
  const [showDevtools, setShowDevtools] = useState(false);
  useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((state) => !state);

    if (!showDevtools && devMode) {
      setShowDevtools(true);
    }
  }, [showDevtools]);

  return (
    <>
      <BaseReactQueryDevtools {...devtoolsOptions} />
      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction {...devtoolsOptions} />
        </Suspense>
      )}
    </>
  );
}
