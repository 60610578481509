import { usePlayerState } from "@src/states/player";

import "./style.less";

export function CurrentTrack() {
  const currentTrack = usePlayerState((state) => state.currentTrack);
  return (
    <div className="CurrentTrack-component">
      <div className="title">{currentTrack.title}</div>
      <div className="artist">{currentTrack.artist}</div>
    </div>
  );
}
