import { env } from "@src/env";
import type { Links } from "@src/types";
import { useMutation } from "@tanstack/react-query";

export function useLinksMutation(idRadioOrCnpj: string) {
  return useMutation<Links, Error>({
    mutationKey: ["links", idRadioOrCnpj],
    mutationFn: async () => {
      if (!idRadioOrCnpj) {
        return;
      }
      const response = await fetch(`${env.host}/api/links/${idRadioOrCnpj}`);
      const data = await response.json();
      if (!response.ok) {
        const messages: Record<string, string> = {
          data_not_found: `Nenhum link foi encontrado com o nome/CNPJ ${idRadioOrCnpj}`,
        };
        const message = messages[data.error] ?? "Erro desconhecido";
        throw new Error(message);
      }
      return data;
    },
    onError: console.log,
  });
}
