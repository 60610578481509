import { useShallow } from "zustand/shallow";

import { useOfflineModeState } from "@src/states/offline_mode";
import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";
import { DEFAULT_TRACK_ID } from "@src/services/player/constants";

import "./style.less";

export function ErrorMessage() {
  // Determina a mensagem de erro
  let error = null;
  const [currentTrackObj, currentTrackId, onAir] = usePlayerState(
    useShallow((state) => [
      state.tracks[state.currentTrackId],
      state.currentTrackId,
      state.onAir,
    ]),
  );
  const status = currentTrackObj?.status;
  const isOnlinePlaying = currentTrackId === DEFAULT_TRACK_ID;

  const offlineModeColor = useOfflineModeState((state) => state.color);
  const clickPlay = (e: string): string => {
    if (offlineModeColor === "green") {
      e += ", dê o Play e use nosso modo offline";
    }
    return e;
  };

  if (!onAir && isOnlinePlaying) {
    error = clickPlay("Rádio temporariamente indisponível");
  } else if (status === "error") {
    error = player.getError();
    if (!error) {
      error = currentTrackObj?.statusText;
    }
  }

  if (error === "Offline track stuck in loadstart.") {
    error = clickPlay("Você está sem internet");
  }
  if (error === "NotAllowedError") {
    // Autoplay falhou
    error = "Dê o Play para ouvir a rádio";
  }

  if (!error) {
    return <></>;
  }
  return <div className="ErrorMessage-component">{error}</div>;
}
