import { getTimestampInSeconds } from "@src/utils/getTimestampInSeconds";

export function checkConnection(
  onlineCallback: () => void,
  offlineCallback: () => void,
  timeoutMs = 5_000,
) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort("TIMEOUT");
  }, timeoutMs);
  const cacheBuster = getTimestampInSeconds();
  const url = `https://clients3.google.com/generate_204?_=${cacheBuster}`;
  fetch(url, {
    signal: controller.signal,
    method: "HEAD",
    redirect: "follow",
    mode: "no-cors",
  })
    .then(onlineCallback)
    .catch(offlineCallback)
    .finally(() => {
      clearTimeout(timeoutId);
    });
}
