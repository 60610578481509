import clsx from "clsx";

import "./style.less";
import type { FormGroupProps } from "./types";

export function FormGroup({ children, className }: FormGroupProps) {
  return (
    <div className={clsx("FormGroup-component", className)}>{children}</div>
  );
}
