import type { QueryClient } from "@tanstack/react-query";
import debounce from "debounce";

import { useOfflineModeState } from "@src/states/offline_mode";
import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";

import { getFileName } from "@src/utils/getFileName";
import { exitOfflineMode } from "@src/utils/offline/exitOfflineMode";
import { getNextOfflineUrl } from "@src/utils/offline/getNextOfflineUrl";

export const playOfflineUrl = debounce(_playOfflineUrl, 500, {
  immediate: true,
});

function _playOfflineUrl(
  playingOrPaused: "playing" | "paused" | null,
  offlineUrl: string,
  queryClient: QueryClient,
) {
  console.log("playOfflineUrl", getFileName(offlineUrl));

  usePlayerState.setState({
    currentTrackId: "offline",
    currentTrack: {
      artist: "Modo Offline",
      title: getFileName(offlineUrl),
      artwork_small: "/offline.png",
      artwork_large: "/offline.png",
    },
  });

  player.removeTrack("offline").then(() => {
    player.addTrack("offline", offlineUrl, false);

    let nextOfflineUrl = offlineUrl;
    player.setExtraEventListener("offline", "playing", () => {
      useOfflineModeState.setState((state) => {
        const newPlayedUrls = new Set(state.playedUrls);
        newPlayedUrls.add(offlineUrl);
        return { playedUrls: newPlayedUrls };
      });

      if (nextOfflineUrl === offlineUrl) {
        nextOfflineUrl = getNextOfflineUrl();
        usePlayerState.setState({
          nextTrack: {
            artist: "Modo Offline",
            title: getFileName(nextOfflineUrl),
            artwork_small: "/offline.png",
            artwork_large: "/offline.png",
          },
        });
      }
    });

    player.setExtraEventListener("offline", "ended", () => {
      const isOnline = useOfflineModeState.getState().isOnline;
      const onAir = usePlayerState.getState().onAir;
      console.log("Offline track ended. isOnline=", isOnline, "onAir=", onAir);
      if (isOnline && onAir) {
        // Terminou de tocar a música offline, e está online
        exitOfflineMode("playing", queryClient);
      } else {
        // Toca a próxima música offline
        playOfflineUrl("playing", nextOfflineUrl, queryClient);
      }
    });

    player.setExtraEventListener("offline", "loadstart", () => {
      setTimeout(() => {
        const { currentTrackId, tracks } = usePlayerState.getState();
        const isOfflinePlaying = currentTrackId === "offline";
        if (isOfflinePlaying && tracks["offline"].log.at(-1) === "loadstart") {
          player.setError("offline", "Offline track stuck in loadstart.");
        }
      }, 1_000);
    });

    player.setExtraEventListener("offline", "error", () => {
      const currentTrackId = usePlayerState.getState().currentTrackId;
      const isOfflinePlaying = currentTrackId === "offline";
      if (isOfflinePlaying) {
        console.warn("Offline track error. Playing next offline track.");
        playOfflineUrl("playing", nextOfflineUrl, queryClient);
      }
    });

    if (playingOrPaused === "playing") {
      player.play("offline");
    }
  });
}
