import clsx from "clsx";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { IconContext } from "react-icons";
import { IoCloseOutline } from "react-icons/io5";

import "./style.less";
import type { ModalProps } from "./types";

export function Modal({ className, isOpen, setIsOpen, children }: ModalProps) {
  useEffect(() => {
    function handleEsc(event: KeyboardEvent) {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    }
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [setIsOpen]);

  return (
    <>
      {isOpen &&
        createPortal(
          <div
            className={clsx("Modal-component", className)}
            onMouseDown={(event) => {
              if (event.target === event.currentTarget) {
                setIsOpen(false);
              }
            }}
          >
            <div className="card content">
              <div className="clickable close" onClick={() => setIsOpen(false)}>
                <IconContext.Provider
                  value={{ color: "#ffffff", className: "icon", size: "2em" }}
                >
                  <IoCloseOutline />
                </IconContext.Provider>
              </div>
              {children}
            </div>
          </div>,
          document.body,
          "modal",
        )}
    </>
  );
}
