import type { MessageToServiceWorker } from "@src/types";

export function destroyCache(
  idRadio: string,
  message: "DESTROY_ALL_CACHE" | "DESTROY_PRECACHE",
) {
  return new Promise<void>((resolve) => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          const sw = registration.active;
          if (sw) {
            console.log(`Service Worker -> ${message}`);
            sw.postMessage({
              idRadio,
              type: message,
            } satisfies MessageToServiceWorker);
          }
          setTimeout(() => {
            registration.unregister().then(() => {
              console.log("Service Worker unregistered successfully.");
              resolve();
            });
          }, 500);
        } else {
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
}
