import { useStreamInfoQuery } from "@src/data/queries/streamInfo";
import type { ReactNode } from "react";

import { Badge } from "@src/components/Badge";

import "./style.less";
import type { StreamInfoProps } from "./types";

export function StreamInfo({ radio }: StreamInfoProps) {
  const streamInfo = useStreamInfoQuery(radio?.url || "");

  let content: ReactNode;
  if (streamInfo.isFetching) {
    content = "Carregando info. do stream...";
  } else if (streamInfo.error || !streamInfo.data) {
    content = "Sem info. do stream (A)";
  } else {
    const { data_source, audio_format } = streamInfo.data;
    if (!data_source || !audio_format) {
      content = "Sem info. do stream (B)";
    } else {
      content = (
        <>
          Servidor: {data_source.replace(".radio.co", "")}
          <br />
          Formato: {audio_format}
        </>
      );
    }
  }

  return <Badge className="StreamInfo-component">{content}</Badge>;
}
