import type { Audio } from "@src/types";
import clsx from "clsx";
import { Cron } from "croner";
import cronstrue from "cronstrue/i18n";
import { format } from "date-fns/format";
import debounce from "debounce";
import { useEffect, useMemo, useState } from "react";
import { IconContext } from "react-icons";
import { PiPlayCircleFill } from "react-icons/pi";
import { TbClockPlay } from "react-icons/tb";

import { Modal } from "@src/components/Modal";

import { getInitialCronState, useCronState } from "@src/states/cron";
import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";

import { createImage } from "@src/utils/createImage";
import { setQueryString } from "@src/utils/setQueryString";

import "./style.less";
import type { ScheduledAudiosProps } from "./types";

const playCronQueue = debounce((idRadio: string) => {
  const currentTrackId = usePlayerState.getState().currentTrackId;
  const playingOrPaused = player.getPlayingOrPaused(currentTrackId);
  const { cronQueue } = useCronState.getState();
  if (playingOrPaused === "playing" && cronQueue.size > 0) {
    const audios = Array.from(cronQueue);
    const urls = audios.map((audio) => audio.url);
    player.playWithFade(
      idRadio,
      "scheduled",
      urls,
      (index) => {
        // onPlaying
        const { id, name, image } = audios[index];
        usePlayerState.setState({
          currentTrackId: "scheduled",
          currentTrack: {
            artist: "Áudio agendado",
            title: name,
            artwork_small: image,
            artwork_large: image,
          },
        });
        useCronState.setState({ playingId: id });
      },
      () => {
        // onEnded
        useCronState.setState(getInitialCronState(), true);
        console.log("scheduled ended");
      },
    );
  }
}, 500);

type ScheduledAudioProps = Audio & {
  visible: boolean;
  idRadio: string;
};

function ScheduledAudio({
  id,
  name,
  url,
  cron,
  visible,
  idRadio,
}: ScheduledAudioProps) {
  const playingId = useCronState((state) => state.playingId);
  const image = useMemo(() => createImage(name), [name]);
  const [proximaExecucao, setProximaExecucao] = useState<Date | null>(null);

  const cronString = useMemo<string>(
    () =>
      cron
        ? cronstrue.toString(cron, {
            use24HourTimeFormat: true,
            locale: "pt_BR",
          })
        : "",
    [cron],
  );

  const key = `${id}_${name}`;
  useEffect(() => {
    if (!cron) return;

    const job = new Cron(cron, { name: key, protect: true }, () => {
      setProximaExecucao(job.nextRun());
      useCronState.setState((state) => {
        const cronQueue = new Set(state.cronQueue);
        cronQueue.add({
          id,
          name,
          url: setQueryString(url, "preloaded", idRadio),
          cron,
          image,
        });
        return { cronQueue };
      });
      playCronQueue(idRadio);
    });

    setProximaExecucao(job.nextRun());
    return () => {
      job.stop();
    };
  }, [key, id, name, url, cron, image, setProximaExecucao, idRadio]);

  if (!visible) {
    return <></>;
  }

  const playing = playingId === id;
  return (
    <div key={id} className={clsx("audio", { playing })}>
      <div className="img-container">
        <img src={image} alt={name} />
        <IconContext.Provider
          value={{ color: "#1b1b1b", className: "icon", size: "4em" }}
        >
          <PiPlayCircleFill className="play" />
        </IconContext.Provider>
      </div>
      <div className="column">
        <div className="text">{name}</div>
        <div className="subtext">Agendamento: {cronString}</div>
        {proximaExecucao && (
          <div className="subtext">
            Próxima execução: {format(proximaExecucao, "HH:mm")}
          </div>
        )}
      </div>
    </div>
  );
}

export function ScheduledAudios({ radio }: ScheduledAudiosProps) {
  const [isOpen, setIsOpen] = useState(false);
  const audios = useMemo(
    () => radio.audios.filter((audio) => audio.cron),
    [radio],
  );

  useEffect(() => {
    const urls = audios.map((audio) => audio.url);
    if (urls.length > 0) {
      console.log("[ScheduledAudios] Preloading audios:", urls);
      player.preloadAudios(radio.folder, urls);
    }
  }, [audios, radio.folder]);

  const scheduledAudios = useMemo(() => {
    return audios.map((item) => (
      <ScheduledAudio
        key={item.id}
        visible={isOpen}
        idRadio={radio.folder}
        {...item}
      />
    ));
  }, [audios, isOpen, radio.folder]);

  return (
    <>
      <IconContext.Provider
        value={{ color: "#ffffff", className: "icon", size: "1.5em" }}
      >
        <div
          className="ScheduledAudios-component clickable"
          onClick={() => setIsOpen(true)}
          data-tooltip-id="footer"
          data-tooltip-html="Áudios<br>agendados"
          data-tooltip-class-name="text-center"
          data-tooltip-place="top"
        >
          <TbClockPlay />
        </div>
      </IconContext.Provider>
      {isOpen ? <></> : scheduledAudios}
      <Modal
        className="ScheduledAudios-modal"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <h2>Áudios agendados</h2>
        <hr />
        <div className="list">
          {audios.length === 0 ? (
            <p className="empty">Nenhum áudio agendado disponível.</p>
          ) : (
            scheduledAudios
          )}
        </div>
      </Modal>
    </>
  );
}
