import { streamToBlob } from "@src/utils/streamToBlob";

export type Provider = "radioindoor" | "elevenlabs" | "azure";

type GetAudioUrl = (
  voice: string,
  text: string,
  volume?: number,
) => Promise<string>;

export const ttsProviders: Record<
  number,
  {
    name: Provider;
    /** `undefined` = no volume control */
    initialVolume?: number;
    initialSpeed: number;
    useServiceWorker: boolean;
    getAudioUrl: GetAudioUrl;
    voices: Record<string, string>;
  }
> = {
  1: {
    name: "radioindoor",
    initialSpeed: 0.9,
    useServiceWorker: true,
    getAudioUrl: async (voice, text, _volume) => {
      const secretKey = "fabriccaradio";
      const response = await fetch("https://fabricca.pro/radioindoor/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text, voice, secretKey }),
      });
      const json = await response.json();
      if (!("url" in json)) {
        throw new Error("Falha ao ler a resposta de TTS");
      }
      return json["url"];
    },
    voices: {
      "pt-BR-FranciscaNeural": "Feminina",
      "pt-BR-AntonioNeural": "Masculina",
    },
  },
  2: {
    name: "elevenlabs",
    initialSpeed: 1.0,
    useServiceWorker: false,
    getAudioUrl: async (voice, text, _volume) => {
      const token = "b3vutd5cvgg5mc1tdnnse8c2kytwapsl";
      const response = await fetch(`https://fabricca.pro/tts?token=${token}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text, voice }),
      });
      if (!response.body) {
        throw new Error("Falha ao ler a resposta de TTS");
      }
      const blob = await streamToBlob(response.body);
      const audioUrl = URL.createObjectURL(blob);
      return audioUrl;
    },
    voices: {
      "Ana Dias": "Ana Dias",
      "Carla - For Conversational": "Carla",
      Gabby: "Gabby",
      "Helena - Kids' Storyteller": "Helena",
      Vanessa: "Vanessa",

      "Fernando Borges": "Fernando Borges",
      "Otto de La Luna": "Otto de La Luna",
      "Will - Deep": "Will - Deep",
      "Will - Dynamic Voiceover": "Will - Dynamic Voiceover",
      Lucke: "Lucke",
    },
  },
  3: {
    name: "azure",
    initialVolume: 2.7,
    initialSpeed: 0.9,
    useServiceWorker: false,
    getAudioUrl: async (voice, text, volume) => {
      const token = "b3vutd5cvgg5mc1tdnnse8c2kytwapsl";
      const response = await fetch(
        `https://fabricca.pro/tts/azure?token=${token}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ text, voice, volume }),
        },
      );
      if (!response.body) {
        throw new Error("Falha ao ler a resposta de TTS");
      }
      const blob = await streamToBlob(response.body);
      const audioUrl = URL.createObjectURL(blob);
      return audioUrl;
    },
    voices: {
      "pt-BR-FranciscaNeural": "Francisca",
      "pt-BR-AntonioNeural": "Antonio",
      "pt-BR-BrendaNeural": "Brenda",
      "pt-BR-DonatoNeural": "Donato",
      "pt-BR-ElzaNeural": "Elza",
      "pt-BR-FabioNeural": "Fabio",
      "pt-BR-GiovannaNeural": "Giovanna",
      "pt-BR-HumbertoNeural": "Humberto",
      "pt-BR-JulioNeural": "Julio",
      "pt-BR-LeilaNeural": "Leila",
      "pt-BR-LeticiaNeural": "Leticia",
      "pt-BR-ManuelaNeural": "Manuela",
      "pt-BR-NicolauNeural": "Nicolau",
      "pt-BR-ThalitaNeural": "Thalita",
      "pt-BR-ValerioNeural": "Valerio",
      "pt-BR-YaraNeural": "Yara",
    },
  },
};
