import { useTTSQuery } from "@src/data/queries/tts";
import { useState } from "react";
import { IconContext } from "react-icons";
import { FaMicrophone } from "react-icons/fa";
import { toast } from "react-toastify";

import { Button } from "@src/components/Button";
import { Checkbox } from "@src/components/Checkbox";
import { FormGroup } from "@src/components/FormGroup";
import { Loader } from "@src/components/Loader";
import { Modal } from "@src/components/Modal";
import { MultiplierControl } from "@src/components/MultiplierControl";
import { Select } from "@src/components/Select";
import { Textarea } from "@src/components/Textarea";

import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";
import { ttsProviders } from "@src/services/tts";

import { setQueryString } from "@src/utils/setQueryString";

import "./style.less";
import type { TTSProps } from "./types";

async function playAudio(
  idRadio: string,
  ttsAudioUrl: string,
  ttsSpeed: number,
  beep: boolean,
  useServiceWorker: boolean,
  onPlaying: () => void,
) {
  const urlsToPreload: string[] = [];
  if (beep) urlsToPreload.push("/beep.mp3");
  if (useServiceWorker) urlsToPreload.push(ttsAudioUrl);
  try {
    await player.preloadAudios(idRadio, urlsToPreload);
  } catch (error) {
    console.error("[playAudio] Failed to preload audio(s):", error);
  }

  const preloadedBeep = setQueryString("/beep.mp3", "preloaded", idRadio);
  const preloadedTTS = useServiceWorker
    ? setQueryString(ttsAudioUrl, "preloaded", idRadio)
    : ttsAudioUrl;

  const previousTrackId = usePlayerState.getState().currentTrackId;
  const playingOrPaused = player.getPlayingOrPaused(previousTrackId);

  let didOnPlaying = false;

  function fadeOutPreviousTrack() {
    if (playingOrPaused === "playing") {
      player.fadeOut(previousTrackId);
    }
  }

  function playPreviousTrackAfterTTS() {
    player.setExtraEventListener("tts", "ended", () => {
      if (playingOrPaused === "playing") {
        player.fadeIn(previousTrackId, true);
      }
      usePlayerState.setState({ currentTrackId: previousTrackId });
      player.removeTrack("tts");
    });
  }

  if (beep) {
    fadeOutPreviousTrack();

    await player.upsertTrack("beep", preloadedBeep, false);
    player.setExtraEventListener("beep", "playing", () => {
      if (!didOnPlaying) {
        didOnPlaying = true;
        onPlaying();
      }
    });

    // Transition from beep to TTS
    player.setExtraEventListener("beep", "ended", async () => {
      await player.upsertTrack("tts", preloadedTTS, false);
      player.setExtraEventListener("tts", "playing", () => {
        player.setSpeed("tts", ttsSpeed);
      });
      playPreviousTrackAfterTTS();
      player.play("tts");
      player.removeTrack("beep");
    });

    player.play("beep");
  } else {
    fadeOutPreviousTrack();

    await player.upsertTrack("tts", preloadedTTS, false);
    player.setExtraEventListener("tts", "playing", () => {
      player.setSpeed("tts", ttsSpeed);
      if (!didOnPlaying) {
        didOnPlaying = true;
        onPlaying();
      }
    });

    playPreviousTrackAfterTTS();
    player.play("tts");
  }
}

export function TTS({ radio }: TTSProps) {
  const { name, voices, initialVolume, initialSpeed, useServiceWorker } =
    ttsProviders[radio.tts];
  const [isOpen, setIsOpen] = useState(false);

  const [beep, setBeep] = useState(false);
  const [volume, setVolume] = useState(initialVolume);
  const [speed, setSpeed] = useState(initialSpeed);
  const [voice, setVoice] = useState(Object.keys(voices)[0]);
  const [text, setText] = useState("");
  const tts = useTTSQuery(name, voice, text, volume);
  const [loading, setLoading] = useState(false);
  const _loading = tts.isLoading || loading;

  return (
    <>
      <IconContext.Provider
        value={{ color: "#ffffff", className: "icon", size: "1.5em" }}
      >
        <div
          className="TTS-component clickable"
          onClick={() => setIsOpen(true)}
          data-tooltip-id="footer"
          data-tooltip-html="Locutor Virtual<br>Texto Para Áudio (TTS)"
          data-tooltip-class-name="text-center"
          data-tooltip-place="top"
        >
          <FaMicrophone />
        </div>
      </IconContext.Provider>
      <Modal className="TTS-modal" isOpen={isOpen} setIsOpen={setIsOpen}>
        <h2>Locutor Virtual</h2>
        <hr />
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setLoading(true);
            tts.refetch().then(
              ({ data }) => {
                if (data) {
                  setText("");
                  playAudio(
                    radio.folder,
                    data,
                    speed,
                    beep,
                    useServiceWorker,
                    () => {
                      toast.success("Áudio TTS gerado com sucesso.");
                      setLoading(false);
                      setTimeout(() => {
                        setIsOpen(false);
                      }, 300);
                    },
                  );
                } else {
                  toast.error("Falha ao gerar áudio TTS (Erro 1)");
                  setLoading(false);
                }
              },
              (error) => {
                console.log("Falha ao gerar áudio TTS:", error);
                toast.error("Falha ao gerar áudio TTS (Erro 2)");
                setLoading(false);
              },
            );
          }}
        >
          <fieldset disabled={_loading}>
            <FormGroup>
              <label htmlFor="voice">Voz</label>
              <Select
                id="voice"
                name="voice"
                required
                value={voice}
                onChange={(event) => setVoice(event.target.value)}
                title="Voz"
              >
                {Object.keys(voices).map((key) => (
                  <option key={key} value={key}>
                    {voices[key]}
                  </option>
                ))}
              </Select>
            </FormGroup>

            {initialVolume && (
              <MultiplierControl
                label="Volume"
                initialValue={initialVolume}
                value={volume || 0}
                setValue={setVolume}
                min={0.1}
                max={5.0}
                step={0.05}
              />
            )}

            <MultiplierControl
              label="Velocidade"
              initialValue={initialSpeed}
              value={speed}
              setValue={setSpeed}
              min={0.75}
              max={1.75}
              step={0.05}
            />

            <FormGroup>
              <label htmlFor="text">Texto</label>
              <Textarea
                id="text"
                name="text"
                required
                value={text}
                onChange={(event) => setText(event.target.value)}
                placeholder="Digite o texto a ser falado"
              />
            </FormGroup>

            <label className="beep">
              <Checkbox
                name="beep"
                checked={beep}
                onChange={(event) => setBeep(event.target.checked)}
              />
              Tocar beep antes da fala
            </label>

            <Button type="submit" disabled={_loading}>
              Falar
            </Button>
            {tts.isError && <span className="error">{tts.error.message}</span>}
            <Loader visible={_loading} />
          </fieldset>
        </form>
      </Modal>
    </>
  );
}
