type PlainObject = Record<string, unknown>;

export function formDataToObject<T extends PlainObject>(formData: FormData): T {
  const plainObject: PlainObject = {};
  formData.forEach((value, key) => {
    if (key in plainObject) {
      const existingValue = plainObject[key];
      plainObject[key] = Array.isArray(existingValue)
        ? [...existingValue, value]
        : [existingValue, value];
    } else {
      plainObject[key] = value;
    }
  });
  return plainObject as T;
}
