import { useLinksMutation } from "@src/data/mutations/links";
import type { Links } from "@src/types";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { Button } from "@src/components/Button";
import { FormGroup } from "@src/components/FormGroup";
import { Input } from "@src/components/Input";
import { Loader } from "@src/components/Loader";
import { PortalDesktop } from "@src/components/PortalDesktop";
import { PortalMobile } from "@src/components/PortalMobile";
import { Select } from "@src/components/Select";

import { useStateWithQueryString } from "@src/hooks/useStateWithQueryString";

import { formatCnpj } from "@src/utils/formatCnpj";
import { isMobile } from "@src/utils/isMobile";
import { onlyDigits } from "@src/utils/onlyDigits";

import "./style.less";

export function Portal() {
  const [findBy, setFindBy] = useStateWithQueryString("document", "findBy");
  const [cnpj, setCnpj] = useStateWithQueryString("", "cnpj");
  const [idRadio, setIdRadio] = useStateWithQueryString("", "idRadio");

  const idRadioOrCnpj = findBy === "folder" ? idRadio : onlyDigits(cnpj);
  const getLinks = useLinksMutation(idRadioOrCnpj);
  const loading = getLinks.isPending;

  const [response, setResponse] = useState<Links>();
  const links = useMemo(() => response?.links || {}, [response?.links]);

  function submitForm() {
    getLinks.mutateAsync().then(setResponse, (error) => {
      console.log("[getLinks]", error);
    });
  }

  useLayoutEffect(() => {
    if (
      (findBy === "document" && onlyDigits(cnpj).length === 14) ||
      (findBy === "folder" && idRadio.length > 0)
    ) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Mantém o CNPJ na query string apenas quando o tipo é document
  // Mantém o ID da rádio na query string apenas quando o tipo é folder
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else if (findBy === "document" && idRadio.length > 0) {
      setIdRadio("");
    } else if (findBy === "folder" && cnpj.length > 0) {
      setCnpj("");
    }
  }, [findBy, cnpj, idRadio, setCnpj, setIdRadio]);

  // Limpa os resultados automaticamente, quando necessário
  useEffect(() => {
    const hasLinks = Object.keys(links).length > 0;
    if (findBy === "document" && onlyDigits(cnpj).length < 14 && hasLinks) {
      setResponse({ found_by: "document", links: {} });
    } else if (findBy === "folder" && idRadio.length === 0 && hasLinks) {
      setResponse({ found_by: "folder", links: {} });
    }
  }, [links, cnpj, idRadio, findBy]);

  return (
    <div className="Portal-page">
      <form
        className="card"
        onSubmit={(event) => {
          event.preventDefault();
          submitForm();
        }}
      >
        <fieldset disabled={loading}>
          <FormGroup>
            <label htmlFor="findBy">Procurar por</label>
            <Select
              id="findBy"
              name="findBy"
              value={findBy}
              onChange={(event) =>
                setFindBy(event.target.value as Links["found_by"])
              }
              title="Procurar por"
              required
            >
              <option value="document">CNPJ</option>
              <option value="folder">Nome da rádio</option>
            </Select>
          </FormGroup>

          {findBy === "folder" && (
            <FormGroup>
              <label htmlFor="idRadio">Nome da rádio</label>
              <Input
                id="idRadio"
                type="text"
                name="idRadio"
                value={idRadio}
                onChange={(event) => {
                  setIdRadio(event.currentTarget.value);
                }}
                required
              />
            </FormGroup>
          )}

          {findBy === "document" && (
            <FormGroup>
              <label htmlFor="cnpj">CNPJ</label>
              <Input
                id="cnpj"
                type="text"
                name="cnpj"
                autoComplete="username"
                value={formatCnpj(cnpj)}
                onChange={(event) => {
                  setCnpj(onlyDigits(event.currentTarget.value));
                }}
                required
              />
            </FormGroup>
          )}

          <Button type="submit" disabled={loading}>
            Procurar links
          </Button>
          {getLinks.isError && (
            <span className="error">{getLinks.error.message}</span>
          )}
          <Loader visible={loading} />
        </fieldset>
      </form>
      {isMobile ? (
        <PortalMobile links={links} />
      ) : (
        <PortalDesktop links={links} />
      )}
    </div>
  );
}
