import { version } from "@src/env";
import type { MessageToServiceWorker } from "@src/types";

function emitServiceWorkerReady() {
  // ensures that the event is emitted after the page has loaded
  setTimeout(() => {
    console.log("emitServiceWorkerReady");
    document.dispatchEvent(new Event("serviceWorkerReady"));
  }, 500);
}

export async function installServiceWorker(
  idRadio: string,
  onActive: (sw: ServiceWorker) => void,
  onError: (error: unknown) => void,
) {
  if (!("serviceWorker" in navigator)) {
    console.warn("Service Worker not supported");
    return;
  }

  const registrations = await navigator.serviceWorker.getRegistrations();
  const unregisterPromises = registrations
    .filter((registration) => registration.scope !== "/")
    .map((registration) => {
      console.log("Removing service worker from scope:", registration.scope);
      return registration.unregister();
    });
  await Promise.all(unregisterPromises);

  try {
    if (window.location.host === "localhost:5173") {
      throw new Error(
        "Service Worker is only available on localhost with `npm run dev:sw` command",
      );
    }

    const registration = await navigator.serviceWorker.register(
      `/service-worker.js?version=${version}`,
      {
        scope: "/",
        updateViaCache: "none",
      },
    );
    console.log("Service Worker registered");

    registration.addEventListener("updatefound", () => {
      console.log("Service Worker updatefound");
      const newWorker = registration.installing;
      if (newWorker) {
        newWorker.addEventListener("statechange", () => {
          console.log(
            "Service Worker updatefound -> statechange to:",
            newWorker.state,
          );
          if (
            newWorker.state === "installed" &&
            navigator.serviceWorker.controller
          ) {
            console.log(
              "Service Worker updatefound -> statechange -> SKIP_WAITING",
            );
            newWorker.postMessage({
              idRadio,
              type: "SKIP_WAITING",
            } satisfies MessageToServiceWorker);
          }
          if (newWorker.state === "activated") {
            console.log("Delayed activation of service worker after update");
            emitServiceWorkerReady();
            onActive(newWorker);
          }
        });
      }
    });

    if (registration.active) {
      console.log("Immediate activation of service worker");
      emitServiceWorkerReady();
      onActive(registration.active);
      registration.update();
    } else {
      console.log("Service Worker will delay its activation");
      const handleControllerChange = () => {
        console.log("handleControllerChange");
        if (navigator.serviceWorker.controller) {
          console.log("Delayed activation of service worker");
          emitServiceWorkerReady();
          onActive(navigator.serviceWorker.controller);
          navigator.serviceWorker.removeEventListener(
            "controllerchange",
            handleControllerChange,
          );
          registration.update();
        }
      };

      navigator.serviceWorker.addEventListener(
        "controllerchange",
        handleControllerChange,
      );
    }
  } catch (error) {
    console.error("Service Worker registration failed:", error);
    onError(error);
  }
}
