export function getServiceWorker() {
  return new Promise<ServiceWorker>((resolve, reject) => {
    /**
     * navigator.serviceWorker.getRegistration()
     * replaces
     * navigator.serviceWorker.ready
     * but also works on the 1st time the page loads
     */
    navigator.serviceWorker.getRegistration().then((registration) => {
      const sw = registration?.active;
      if (sw) {
        resolve(sw);
      } else {
        reject(new Error("There is no active Service Worker"));
      }
    });
  });
}
