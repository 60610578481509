import { useRegisterRadioSessionMutation } from "@src/data/mutations/registerRadioSession";
import { useEffect, useRef } from "react";

import { useMeuPlayState } from "@src/states/meuplay";
import { useOfflineModeState } from "@src/states/offline_mode";

export function useRadioSession() {
  const { radio, loginFormData, isValid } = useMeuPlayState();
  const idRadio = radio?.folder;
  const cnpj = loginFormData?.cnpj;

  const intervalRef = useRef<NodeJS.Timeout>(null);
  const upsertRadioSession = useRegisterRadioSessionMutation("POST");
  const closeRadioSession = useRegisterRadioSessionMutation("DELETE");

  function startRadioSession(idRadio: string, cnpj?: string) {
    if (useOfflineModeState.getState().isOnline) {
      upsertRadioSession.mutate({ idRadio, cnpj });
    }

    intervalRef.current = setInterval(() => {
      if (useOfflineModeState.getState().isOnline) {
        upsertRadioSession.mutate({ idRadio, cnpj });
      }
    }, 45_000);
  }

  function endRadioSession() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;

      if (idRadio) {
        closeRadioSession.mutate({ idRadio, cnpj });
      }
    }
  }

  useEffect(() => {
    if (isValid && idRadio) {
      startRadioSession(idRadio, cnpj);
    } else {
      endRadioSession();
    }
    return () => {
      endRadioSession();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);
}
