import { useQuery } from "@tanstack/react-query";

import { type Provider, ttsProviders } from "@src/services/tts";

export function useTTSQuery(
  provider: Provider,
  voice: string,
  text: string,
  volume?: number,
) {
  return useQuery<string>({
    enabled: false,
    queryKey: ["tts", { provider, voice, text, volume }],
    queryFn: async () => {
      const providerObj = Object.values(ttsProviders).find(
        (_) => _.name === provider,
      );
      if (!providerObj) {
        throw new Error(`TTS provider ${provider} not found`);
      }
      return await providerObj.getAudioUrl(voice, text, volume);
    },
  });
}
