import type { QueryClient } from "@tanstack/react-query";

import { useOfflineModeState } from "@src/states/offline_mode";
import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";
import { DEFAULT_TRACK_ID } from "@src/services/player/constants";

import { getNextOfflineUrl } from "@src/utils/offline/getNextOfflineUrl";
import { playOfflineUrl } from "@src/utils/offline/playOfflineUrl";

export function enterOfflineMode(queryClient: QueryClient) {
  console.warn("Entering offline mode");

  // Remove online track information from cache
  queryClient.removeQueries({
    predicate: (query) => query.queryKey[0] === "tracks",
  });

  const { currentTrackId } = usePlayerState.getState();
  const playingOrPaused = player.getPlayingOrPaused(currentTrackId);

  const { cachedUrls } = useOfflineModeState.getState();
  if (cachedUrls.size > 0) {
    player.pause(DEFAULT_TRACK_ID);

    const offlineUrl = getNextOfflineUrl();
    playOfflineUrl(playingOrPaused, offlineUrl, queryClient);
  } else {
    console.warn("It went offline, but there is no offline audio to play");
  }
}
