import type { LoginFormData, Radio } from "@src/types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import { getZustandStorage } from "@src/services/storage";

import { getIdRadio } from "@src/utils/getIdRadio";

type MeuPlayState = {
  /** 0 a 100 */
  volume: number;
  isValid: boolean;
  loginFormData?: LoginFormData;
  radio?: Radio;
};

const storage = getZustandStorage<Partial<MeuPlayState>>();

export function getInitialMeuPlayState(): MeuPlayState {
  return {
    volume: 100,
    isValid: false,
  };
}

export const useMeuPlayState = create<MeuPlayState>()(
  persist(getInitialMeuPlayState, {
    name: `MeuPlayState-${getIdRadio()}`,
    storage,
    partialize: (state) => ({
      volume: state.volume,
      loginFormData: state.loginFormData,
      radio: state.radio,
    }),
  }),
);
