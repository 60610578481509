export function createImage(text: string): string {
  const canvas = document.createElement("canvas");
  canvas.height = 600;
  canvas.width = 600;
  const context = canvas.getContext("2d");

  if (context) {
    // Criar gradiente vertical
    const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, "#cdcdcd"); // Top
    gradient.addColorStop(0.5, "#ffffff"); // Middle
    gradient.addColorStop(1, "#cdcdcd"); // Bottom

    // Preencher o fundo com o gradiente
    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);

    const maxWidth = canvas.width - 20;
    const baseFontSize = 55;
    const fontFamily = "'Rubik', monospace";
    let fontSize = baseFontSize;

    const lineHeightFactor = 1.2; // Line height as a multiple of font size
    let lineHeight = fontSize * lineHeightFactor;

    const words = text.split(" ");
    let line = "";
    let lines: string[] = [];

    // Function to split text into lines based on font size
    const splitText = () => {
      lineHeight = fontSize * lineHeightFactor;
      line = "";
      lines = [];
      for (const word of words) {
        const testLine = line + word + " ";
        context.font = `normal ${fontSize}px ${fontFamily}`;
        const testWidth = context.measureText(testLine).width;

        if (testWidth > maxWidth && line) {
          lines.push(line.trim());
          line = word + " ";
        } else {
          line = testLine;
        }
      }
      if (line) lines.push(line.trim());
    };

    // Adjust font size until the text fits within the canvas
    do {
      context.font = `normal ${fontSize}px ${fontFamily}`;
      splitText();
      if (lines.length * lineHeight > canvas.height) {
        fontSize -= 2; // Reduce font size to fit
      } else {
        break;
      }
    } while (fontSize > 10); // Minimum font size threshold

    // Draw the text
    context.fillStyle = "#ff5510";
    context.textAlign = "center";
    context.textBaseline = "middle";

    const x = canvas.width / 2;
    const offsetY =
      (canvas.height - lines.length * lineHeight) / 2 + lineHeight / 2;

    lines.forEach((line, index) => {
      context.fillText(line, x, offsetY + index * lineHeight);
    });
  }

  return canvas.toDataURL("image/png");
}
