import { useRef } from "react";
import Skeleton from "react-loading-skeleton";

import { ErrorMessage } from "@src/components/ErrorMessage";
import { PlayPause } from "@src/components/PlayPause";

import "./style.less";
import type { ArtworkProps } from "./types";

export function Artwork({ currentTrack }: ArtworkProps) {
  const artworkRef = useRef<HTMLImageElement | null>(null);
  const skeletonRef = useRef<HTMLDivElement | null>(null);
  return (
    <div className="Artwork-component">
      <PlayPause />
      <ErrorMessage />
      {currentTrack.artwork_large && (
        <img
          ref={artworkRef}
          src={currentTrack.artwork_large}
          title={`${currentTrack.artist} - ${currentTrack.title}`}
          onLoad={() => {
            setTimeout(() => {
              if (artworkRef.current) {
                artworkRef.current.classList.add("visible");
              }
              setTimeout(() => {
                if (skeletonRef.current) {
                  skeletonRef.current.classList.add("invisible");
                }
              }, 1_500);
            }, 100);
          }}
        />
      )}
      <div className="skeleton" ref={skeletonRef}>
        <Skeleton inline={false} />
      </div>
    </div>
  );
}
