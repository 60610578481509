import clsx from "clsx";
import { useState } from "react";

import { Button } from "@src/components/Button";

import "./style.less";
import type { PortalMobileProps } from "./types";

export function PortalMobile({ links }: PortalMobileProps) {
  const [radio, setRadio] = useState("");
  const folders = Object.keys(links);

  if (folders.length === 0) {
    return <></>;
  }

  return (
    <div className="PortalMobile-component card">
      {folders.map((folder) => (
        <div key={folder} className="radio">
          <Button
            onClick={() => {
              setRadio(radio === folder ? "" : folder);
            }}
            className={clsx({
              active: radio === folder,
              inactive: radio !== folder,
            })}
          >
            {folder}
          </Button>
          {radio === folder && (
            <div className="links">
              <a href={links[folder].recommended} target="_blank">
                Recomendado Full
              </a>
              <a href={links[folder].alternative} target="_blank">
                Alternativo Full
              </a>
              <a href={links[folder].recommended_lite} target="_blank">
                Recomendado Lite
              </a>
              <a href={links[folder].alternative_lite} target="_blank">
                Alternativo Lite
              </a>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
