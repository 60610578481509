import type { QueryClient } from "@tanstack/react-query";

import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";
import { DEFAULT_TRACK_ID } from "@src/services/player/constants";

import { setupOnlineMode } from "@src/utils/online/setupOnlineMode";

export function exitOfflineMode(
  playingOrPaused: "playing" | "paused" | null,
  queryClient: QueryClient,
) {
  console.warn(`Exiting offline mode (${playingOrPaused})`);
  player.removeTrack("offline").then(() => {
    usePlayerState.setState({ currentTrackId: DEFAULT_TRACK_ID });

    setupOnlineMode(queryClient);
    if (playingOrPaused === "playing") {
      player.play(DEFAULT_TRACK_ID);
    }
  });
}
