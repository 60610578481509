import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { useOfflineModeState } from "@src/states/offline_mode";
import { usePlayerState } from "@src/states/player";

import { DEFAULT_TRACK_ID } from "@src/services/player/constants";
import { factory } from "@src/services/radioco";

export function useTracksQuery(streaming_id: string) {
  const isOnline = useOfflineModeState((state) => state.isOnline);

  const enabled = useMemo(() => {
    if (streaming_id && isOnline) {
      console.log("useTracksQuery enabled");
      return true;
    } else {
      console.log("useTracksQuery disabled");
      return false;
    }
  }, [streaming_id, isOnline]);

  const query = useQuery({
    enabled,
    queryKey: ["tracks", streaming_id],
    queryFn: async () => {
      const radioco = factory(streaming_id);

      // Assert connection with Mercure Hub
      radioco.startEventSource();

      const [currentTrack, nextTrack, trackHistory, onAir] = await Promise.all([
        radioco.getCurrentTrack(),
        radioco.getNextTrack(),
        radioco.getTrackHistory(),
        radioco.isOnAir(),
      ]);
      return { currentTrack, nextTrack, trackHistory, onAir };
    },
    refetchInterval: 5_000,
  });

  const currentTrackId = usePlayerState((state) => state.currentTrackId);
  useEffect(() => {
    const isOnlinePlaying = currentTrackId === DEFAULT_TRACK_ID;
    if (isOnlinePlaying && enabled) {
      console.log("Refetching tracks...");
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrackId, enabled]);

  return query;
}
