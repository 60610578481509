import * as Sentry from "@sentry/react";
import { devMode } from "@src/env";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ReactQueryDevtools } from "@src/components/ReactQueryDevtools";

import { ErrorBoundary } from "@src/templates/ErrorBoundary";
import { MeuPlay } from "@src/templates/MeuPlay";

import { Autoplay } from "@src/pages/Autoplay";
import { LiteRedirect } from "@src/pages/LiteRedirect";
import { Login } from "@src/pages/Login";
import { PlayerRenderer } from "@src/pages/PlayerRenderer";
import { Portal } from "@src/pages/Portal";

import "./main.less";

if (!devMode) {
  Sentry.init({
    dsn: "https://8be559c93559d09d04fc83bfd7859bed@o4508846131970048.ingest.us.sentry.io/4508846134001664",
    ignoreErrors: [/request was interrupted/],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://fabricca.pro/"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <MeuPlay />,
    ErrorBoundary,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "portal",
        element: <Portal />,
      },
      {
        path: "autoplay",
        element: <Autoplay />,
      },
      {
        path: "lite/*",
        element: <LiteRedirect />,
      },
      {
        path: ":idRadio",
        element: <PlayerRenderer />,
      },
      {
        path: ":idRadio/:cnpj",
        element: <PlayerRenderer />,
      },
      {
        path: ":idRadio/:cnpj/:senha",
        element: <PlayerRenderer />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools />
    </QueryClientProvider>
    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      transition={Bounce}
    />
  </StrictMode>,
);
