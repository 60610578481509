import { Button } from "@src/components/Button";
import { FormGroup } from "@src/components/FormGroup";
import { InputRange } from "@src/components/InputRange";

import "./style.less";
import type { MultiplierControlProps } from "./types";

export function MultiplierControl({
  label,
  initialValue,
  value,
  setValue,
  min,
  max,
  step,
}: MultiplierControlProps) {
  return (
    <FormGroup className="MultiplierControl-component">
      <div className="row">
        <label htmlFor="value">{label}</label>
        <span>{value.toFixed(2)}x</span>
      </div>
      <div className="row">
        <InputRange
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e) => {
            const playbackRate = parseFloat(e.target.value);
            setValue(playbackRate);
          }}
        />
        <Button type="button" onClick={() => setValue(initialValue)}>
          RESET
        </Button>
      </div>
    </FormGroup>
  );
}
