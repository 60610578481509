import { IconContext } from "react-icons";
import { FaFacebook, FaGlobe, FaInstagram } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

import { isMobile } from "@src/utils/isMobile";

import "./style.less";
import type { SocialMediaLink } from "./types";

const links: SocialMediaLink[] = [
  {
    title: "Instagram",
    url: "https://www.instagram.com/fabriccamultimidia/",
    icon: FaInstagram,
  },
  {
    title: "Facebook",
    url: "https://www.facebook.com/fabriccamultimidia/",
    icon: FaFacebook,
  },
  {
    title: "Site",
    url: "https://fabriccamultimidia.com.br/",
    icon: FaGlobe,
  },
];

export function SocialMedia() {
  return (
    <IconContext.Provider
      value={{ color: "#ffffff", className: "icon", size: "2em" }}
    >
      <div className="SocialMedia-component">
        <Tooltip
          id="social-media"
          disableTooltip={() => isMobile}
          style={{ zIndex: 4 }}
        />
        {links.map((link) => {
          const Icon = link.icon;
          return (
            <a
              key={link.title}
              title={link.title}
              href={link.url}
              target="_blank"
              data-tooltip-id="social-media"
              data-tooltip-content={link.title}
              data-tooltip-place="left"
            >
              <Icon />
            </a>
          );
        })}
      </div>
    </IconContext.Provider>
  );
}
