import type { HistoryTrackInfo, Track, TrackId, TrackInfo } from "@src/types";
import { create } from "zustand";

import { DEFAULT_TRACK_ID } from "@src/services/player/constants";

export type PlayerState = {
  currentTrackId: TrackId;
  tracks: Record<TrackId, Track>;
  currentTrack: TrackInfo;
  nextTrack?: TrackInfo;
  trackHistory: HistoryTrackInfo[];
  onAir: boolean;
};

export function getInitialPlayerState(): PlayerState {
  return {
    currentTrackId: DEFAULT_TRACK_ID,
    tracks: {},
    currentTrack: {
      artist: "",
      title: "",
      artwork_small: "",
      artwork_large: "",
    },
    nextTrack: {
      artist: "",
      title: "",
      artwork_small: "",
      artwork_large: "",
    },
    trackHistory: [],
    onAir: true,
  };
}

export const usePlayerState = create<PlayerState>()(getInitialPlayerState);
