import { devMode } from "@src/env";

import { useInstallPwaState } from "./state";
import "./style.less";

window.addEventListener(
  "beforeinstallprompt",
  function onBeforeInstallPrompt(event) {
    event.preventDefault();
    useInstallPwaState.setState({ deferredPrompt: event });
  },
);

window.addEventListener("appinstalled", function onAppInstalled() {
  useInstallPwaState.setState({ installed: true });
});

export function InstallPwa() {
  const { deferredPrompt, installed } = useInstallPwaState();
  if ((!deferredPrompt && !devMode) || installed) {
    return <></>;
  }
  return (
    <img
      className="InstallPwa-component clickable"
      src="/pwa_install.svg"
      alt="Instalar como PWA"
      onClick={async () => {
        if (!deferredPrompt) return;
        const userChoice = await deferredPrompt.prompt();
        console.log("userChoice", userChoice);
        if (userChoice.outcome === "accepted") {
          useInstallPwaState.setState({ installed: true });
        }
      }}
    />
  );
}
