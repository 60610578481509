import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { IconContext } from "react-icons";
import { PiPauseCircleFill, PiPlayCircleFill } from "react-icons/pi";
import { Oval } from "react-loader-spinner";

import { LoginInfo } from "@src/components/LoginInfo";
import { Version } from "@src/components/Version";

import { useRadio } from "@src/hooks/useRadio";

import { getTimestampInSeconds } from "@src/utils/getTimestampInSeconds";
import { destroyCache } from "@src/utils/serviceWorker/destroyCache";
import { setQueryString } from "@src/utils/setQueryString";

import "./style.less";

type AudioState = "loading" | "playing" | "paused";

export function LitePlayer() {
  const { radio, logout } = useRadio();
  const audioRef = useRef<HTMLAudioElement>(null);
  const sourceRef = useRef<HTMLSourceElement>(null);
  const [error, setError] = useState<Error | ErrorEvent>();
  const [state, setState] = useState<AudioState>("paused");

  useEffect(() => {
    if (radio?.folder) {
      destroyCache(radio.folder, "DESTROY_ALL_CACHE");
    }
  }, [radio?.folder]);

  const play = useCallback(() => {
    if (radio && audioRef.current && sourceRef.current) {
      const cacheBuster = getTimestampInSeconds();
      sourceRef.current.src = setQueryString(
        radio.url,
        "cache_buster",
        String(cacheBuster),
      );
      audioRef.current.load();

      audioRef.current.play();
    }
  }, [radio]);

  const pause = useCallback(() => {
    if (audioRef.current && sourceRef.current) {
      audioRef.current.pause();

      sourceRef.current.src = "";
      audioRef.current.load();

      setState("paused");
    }
  }, []);

  const stateRef = useRef<AudioState>(state);
  useEffect(() => {
    stateRef.current = state;
    if (state === "loading") {
      setTimeout(() => {
        if (
          stateRef.current === "loading" &&
          audioRef.current &&
          audioRef.current.readyState < HTMLMediaElement.HAVE_CURRENT_DATA
        ) {
          setError(new Error("Tentando novamente..."));
        }
      }, 10_000);
    }
  }, [state]);

  useEffect(() => {
    if (error) {
      console.log("Auto retrying in 1 second...");
      setTimeout(() => {
        pause();
        setError(undefined);
        play();
      }, 1_000);
    }
  }, [error, pause, play]);

  const didEffect = useRef(false);
  useLayoutEffect(() => {
    if (didEffect.current || !audioRef.current) return;
    didEffect.current = true;

    audioRef.current.addEventListener("play", () => {
      setState("loading");
    });
    audioRef.current.addEventListener("playing", () => {
      setState("playing");
    });
    audioRef.current.addEventListener("pause", () => {
      setState("paused");
    });
    audioRef.current.addEventListener("ended", () => {
      setError(new Error("Ended"));
      setState("paused");
    });
    audioRef.current.addEventListener("error", (error) => {
      console.error(error);
      setError(error);
      setState("paused");
    });

    const autoplay = window.location.search.includes("autoplay=1");
    if (autoplay) {
      console.log("📻 Autoplaying...");
      play();
    }
  }, [play]);

  if (!radio) {
    return <></>;
  }

  return (
    <div className="LitePlayer-page">
      <audio ref={audioRef} preload="none" crossOrigin="anonymous">
        <source ref={sourceRef} />
      </audio>
      <Version idRadio={radio.folder} hasServiceWorker={false} />
      <LoginInfo logged_in_as={radio.radio_name} logout={logout} />
      <IconContext.Provider
        value={{ color: "#ff5510", className: "icon", size: "8em" }}
      >
        {state === "paused" && (
          <PiPlayCircleFill className="play clickable" onClick={play} />
        )}
        {state === "playing" && (
          <PiPauseCircleFill className="pause clickable" onClick={pause} />
        )}
        <Oval
          visible={state === "loading"}
          height={100}
          width={100}
          color="#ff5510"
          secondaryColor="#000000"
          strokeWidth={4}
        />
      </IconContext.Provider>
      {error && <div className="error">{error.message}</div>}
    </div>
  );
}
