export function setQueryString(
  url: string,
  key: string,
  value: string,
): string {
  if (url.startsWith("/")) {
    url = window.location.origin + url;
  }
  const parsedUrl = new URL(url);
  parsedUrl.searchParams.set(key, value);
  return parsedUrl.toString();
}
