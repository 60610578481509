import { Oval } from "react-loader-spinner";

import type { LoaderProps } from "./types";

export function Loader({ visible }: LoaderProps) {
  return (
    <Oval
      wrapperClass="Loader-component"
      visible={visible}
      height={100}
      width={100}
      color="#ff5510"
      secondaryColor="#000000"
      strokeWidth={4}
    />
  );
}
