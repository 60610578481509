import { env } from "@src/env";
import type { Radio } from "@src/types";
import { useQuery } from "@tanstack/react-query";

import { Api } from "@src/services/api";

export function useOfflineAudiosQuery(radio: Radio) {
  return useQuery({
    enabled: false, // aguarda chamar o refetch
    queryKey: ["offlineAudios", radio.folder],
    queryFn: async () => {
      const audioLinks = await Api.getAudioLinks(radio.folder);
      const cacheableAudioLinks = audioLinks.map((link) => {
        const url = `${env.host}/api/play-audio/${radio.folder}/${link}`;
        return encodeURI(url);
      });
      return { cacheableAudioLinks };
    },
  });
}
