import { useQueryClient } from "@tanstack/react-query";

import { useOfflineModeState } from "@src/states/offline_mode";
import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";
import { DEFAULT_TRACK_ID } from "@src/services/player/constants";

import { enterOfflineMode } from "@src/utils/offline/enterOfflineMode";
import { exitOfflineMode } from "@src/utils/offline/exitOfflineMode";

export function TestButtons() {
  const queryClient = useQueryClient();
  const currentTrackId = usePlayerState((state) => state.currentTrackId);
  const cachedUrls = useOfflineModeState((state) => state.cachedUrls);

  const testSentry = (
    <button
      type="button"
      onClick={() => {
        throw new Error("This is your first error!");
      }}
    >
      Testar Sentry
    </button>
  );

  const isOnlinePlaying = currentTrackId === DEFAULT_TRACK_ID;
  if (isOnlinePlaying && cachedUrls.size >= 1) {
    return (
      <>
        {testSentry}
        <button
          type="button"
          onClick={() => {
            useOfflineModeState.setState({
              enableConnectionMonitor: false,
              isOnline: false,
            });
            enterOfflineMode(queryClient);
          }}
        >
          Entrar no modo offline
        </button>
      </>
    );
  }

  const isOfflinePlaying = currentTrackId === "offline";
  if (isOfflinePlaying) {
    return (
      <>
        {testSentry}
        <button
          type="button"
          onClick={() => {
            enterOfflineMode(queryClient);
          }}
        >
          Próxima música
        </button>
        <button
          type="button"
          onClick={() => {
            player.seekToLastFiveSeconds("offline");
          }}
        >
          Avançar para o final da música
        </button>
        <button
          type="button"
          onClick={() => {
            useOfflineModeState.setState({
              enableConnectionMonitor: true,
              isOnline: true,
            });
            exitOfflineMode(
              player.getPlayingOrPaused(currentTrackId),
              queryClient,
            );
          }}
        >
          Sair do modo offline
        </button>
      </>
    );
  }

  return testSentry;
}
