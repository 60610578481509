import type { EventName, PlayerStatus, Track, TrackId } from "@src/types";
import { produce } from "immer";

import { useMeuPlayState } from "@src/states/meuplay";
import { PlayerState, usePlayerState } from "@src/states/player";

import { alwaysResolve } from "@src/utils/alwaysResolve";
import { getTimestampInSeconds } from "@src/utils/getTimestampInSeconds";
import { isSafari } from "@src/utils/isSafari";
import { exchangeMessage } from "@src/utils/serviceWorker/exchangeMessage";
import { setQueryString } from "@src/utils/setQueryString";

import { DEFAULT_TRACK_ID, STATUS_MAP } from "./constants";
import { dummy } from "./dummy";

export class Player {
  #locked = false;

  #verbose = false;

  #eventListeners: Record<
    TrackId,
    {
      [eventName in EventName]?: () => void;
    }
  > = {};

  #extraEventListeners: Record<
    TrackId,
    {
      [eventName in EventName]?: () => void;
    }
  > = {};

  #context: AudioContext | null = null;

  unlock(called_by: string) {
    if (this.#locked) {
      console.log(`${called_by}: player unlocked 🔓`);
      this.#locked = false;
    }
  }

  getContext() {
    if (!this.#context) {
      this.#context = new AudioContext();
    }
    if (this.#context.state === "suspended") {
      console.log("Resuming audio context...");
      this.#context.resume();
    }
    return this.#context;
  }

  setSpeed(trackId: TrackId, speed: number) {
    const { audioElement } = this.getTrack(trackId);
    if (audioElement.playbackRate !== speed) {
      audioElement.playbackRate = speed;
    }
  }

  onEvent(trackId: TrackId, eventName: EventName, ...args: unknown[]) {
    if (this.#verbose && args.length > 0) {
      console.log(`${eventName} args:`, ...args);
    }

    switch (eventName) {
      case "error":
      case "ended":
      case "pause":
      case "playing": {
        const { currentTrackId } = usePlayerState.getState();
        if (trackId === currentTrackId) {
          this.unlock(`${trackId} ${eventName} event`);
        }
        break;
      }
      case "waiting":
      case "stalled": {
        setTimeout(() => {
          const { tracks, currentTrackId } = usePlayerState.getState();
          if (trackId === currentTrackId && trackId in tracks) {
            const lastEvent = tracks[trackId].log.at(-1);
            if (lastEvent === "waiting" || lastEvent === "stalled") {
              console.log(
                trackId,
                `Resetting because it was ${lastEvent} for 15 seconds`,
              );
              this.resetTrack(trackId).then(() => {
                this.play(trackId);
              });
            }
          }
        }, 15_000);
        break;
      }
    }
  }

  getPlayingOrPaused(trackId: TrackId) {
    const { tracks } = usePlayerState.getState();
    const log = tracks[trackId]?.log || [];
    for (let i = log.length - 1; i >= 0; i--) {
      if (log[i] === "play") {
        return "playing";
      }
      if (log[i] === "pause") {
        return "paused";
      }
    }
    return null;
  }

  getError(): string {
    try {
      const { currentTrackId } = usePlayerState.getState();
      const { audioElement } = this.getTrack(currentTrackId);
      const error = audioElement.error;
      if (error) {
        return error.message;
      }
    } catch (error) {
      if (typeof error === "string") {
        return error;
      } else if (error instanceof Error) {
        return error.message;
      } else {
        console.warn("Erro desconhecido:", error);
      }
    }
    return "";
  }

  setExtraEventListener(
    trackId: TrackId,
    eventName: EventName,
    listener: () => void,
  ) {
    // prettier-ignore
    this.#extraEventListeners[trackId] = this.#extraEventListeners[trackId] || {};
    this.#extraEventListeners[trackId][eventName] = listener;
  }

  unsetExtraEventListener(trackId: TrackId, eventName: EventName) {
    if (
      this.#extraEventListeners[trackId] &&
      this.#extraEventListeners[trackId][eventName]
    ) {
      delete this.#extraEventListeners[trackId][eventName];
    }
  }

  applyCacheBuster(url: string): string {
    const cacheBuster = getTimestampInSeconds();
    return setQueryString(url, "cache_buster", String(cacheBuster));
  }

  async playWithFade(
    idRadio: string,
    trackId: TrackId,
    urls: string[],
    onPlaying: (index: number) => void,
    onEnded: () => void,
  ) {
    const previousTrackId = usePlayerState.getState().currentTrackId;
    const playingOrPaused = this.getPlayingOrPaused(previousTrackId);
    if (playingOrPaused === "playing") {
      this.fadeOut(previousTrackId, 0);
    }

    let index = 0;
    await this.upsertTrack(
      trackId,
      setQueryString(urls[index], "preloaded", idRadio),
      false,
    );

    let didOnPlaying = false;
    this.setExtraEventListener(trackId, "playing", () => {
      if (!didOnPlaying) {
        didOnPlaying = true;
        onPlaying(index);
      }
    });

    this.setExtraEventListener(trackId, "ended", async () => {
      index++;
      const hasNext = typeof urls[index] !== "undefined";
      if (hasNext) {
        didOnPlaying = false;
        await this.upsertTrack(trackId, urls[index], false);
        this.play(trackId);
      } else {
        if (playingOrPaused === "playing") {
          this.fadeIn(previousTrackId, false);
        }
        usePlayerState.setState({ currentTrackId: previousTrackId });
        await this.removeTrack(trackId);
        onEnded();
      }
    });

    this.play(trackId);
  }

  async upsertTrack(trackId: TrackId, url: string, isStreaming: boolean) {
    await this.removeTrack(trackId);
    this.addTrack(trackId, url, isStreaming);
  }

  addTrack(trackId: TrackId, url: string, isStreaming: boolean) {
    const { tracks } = usePlayerState.getState();
    if (trackId in tracks) {
      throw new Error(`Track ID ${trackId} is already being used.`);
    }
    if (!url) {
      throw new Error(`Track ID ${trackId} requires an URL.`);
    }
    if (isStreaming) {
      url = this.applyCacheBuster(url);
      console.log("Cache buster aplicado no streaming:", url);
    }
    const audioElement = new Audio(url);
    this.#eventListeners[trackId] = {};
    for (const event in STATUS_MAP) {
      const eventName = event as EventName;
      const { description, status, enabled } = STATUS_MAP[eventName];
      const eventDescription = `[${event}] ${description}`;
      if (enabled) {
        const listener = (...args: unknown[]) => {
          let _status: PlayerStatus;
          if (status === "auto") {
            _status = audioElement.paused ? "paused" : "playing";
          } else {
            _status = status;
          }
          console.log(trackId, _status, eventDescription);
          usePlayerState.setState(
            produce((state: PlayerState) => {
              if (trackId in state.tracks) {
                state.tracks[trackId].status = _status;
                state.tracks[trackId].statusText = eventDescription;
                state.tracks[trackId].log.push(eventName);
              } else {
                console.warn(
                  `Event ${eventName} was triggered for a non-existent track ${trackId}`,
                );
              }
            }),
          );
          this.onEvent(trackId, eventName, ...args); // chamar onEvent depois de usePlayerState.setState()

          // Extra event listeners
          if (
            trackId in this.#extraEventListeners &&
            eventName in this.#extraEventListeners[trackId]
          ) {
            this.#extraEventListeners[trackId][eventName]!();
          }
        };
        this.#eventListeners[trackId][eventName] = listener;
        audioElement.addEventListener(event, listener);
      }
    }

    console.warn(`Adicionando track ${trackId}`);
    usePlayerState.setState(
      produce((state: PlayerState) => {
        state.tracks[trackId] = {
          url,
          status: "paused",
          statusText: "",
          isStreaming,
          audioElement,
          log: [],
        };
      }),
    );
    audioElement.crossOrigin = "anonymous";
    audioElement.preload = "none";
  }

  async destroyContext() {
    return new Promise<void>((resolve) => {
      console.warn("[destroyContext]");
      if (!this.#context) {
        return resolve();
      }
      this.#context.close().finally(() => {
        this.unlock("destroyContext");
        this.#context = null;
        return resolve();
      });
    });
  }

  async removeAllTracks() {
    for (const trackId in this.#eventListeners) {
      console.log(`[removeAllTracks] track ${trackId}`);
      await this.removeTrack(trackId);
    }
  }

  async removeTrack(trackId: TrackId) {
    return new Promise<void>((resolve) => {
      let track: Track;
      try {
        track = this.getTrack(trackId);
      } catch {
        // same as removed, do nothing
        resolve();
        return;
      }
      const { audioElement, sourceNode, gainNode } = track;
      for (const event in this.#eventListeners[trackId]) {
        const eventName = event as EventName;
        audioElement.removeEventListener(
          event,
          this.#eventListeners[trackId][eventName]!,
        );
        delete this.#eventListeners[trackId][eventName];
      }
      delete this.#eventListeners[trackId];

      // for (const event in this.#extraEventListeners[trackId]) {
      //   this.unsetExtraEventListener(trackId, event as EventName);
      // }

      if (sourceNode || gainNode) {
        if (gainNode) gainNode.disconnect();
        if (sourceNode) sourceNode.disconnect();
        console.log(trackId, "disconnected from AudioContext");
      }
      audioElement.pause();
      audioElement.currentTime = 0;
      if (audioElement.parentNode) {
        audioElement.parentNode.removeChild(audioElement);
      }
      audioElement.src = dummy;
      audioElement.load();

      usePlayerState.setState((state) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [trackId]: _, ...tracks } = state.tracks;
        console.warn(`Removendo track ${trackId}`);
        return { tracks };
      });

      if (usePlayerState.getState().currentTrackId === trackId) {
        console.log(
          `Current track removed, setting currentTrackId to default (${DEFAULT_TRACK_ID})`,
        );
        usePlayerState.setState({ currentTrackId: DEFAULT_TRACK_ID });
      }

      const remainingTracks = Object.keys(this.#eventListeners).length;
      if (remainingTracks) {
        resolve();
      } else {
        this.destroyContext().then(resolve);
      }
    });
  }

  async resetTrack(trackId: string) {
    if (this.#locked) {
      console.warn("Player is locked, ignoring resetTrack() call", trackId);
      return;
    }

    const { url, isStreaming } = this.getTrack(trackId);
    await this.removeTrack(trackId);
    this.addTrack(trackId, url, isStreaming);
  }

  hasTrack(trackId: TrackId): boolean {
    const { tracks } = usePlayerState.getState();
    return trackId in tracks;
  }

  getTrack(trackId: TrackId): Track {
    const { tracks } = usePlayerState.getState();
    if (trackId in tracks) {
      return tracks[trackId];
    }
    throw new Error(`Track ID ${trackId} does not exist.`);
  }

  async preloadAudios(idRadio: string, _urls: string[]) {
    const urls = _urls.map((url) => setQueryString(url, "preloaded", idRadio));
    const { success, result } = await alwaysResolve(
      exchangeMessage({
        idRadio,
        type: "ADD_TO_CACHE",
        payload: {
          cacheName: "preloadAudios",
          urls,
        },
      }),
    );
    if (success && result.type === "RESPONSE_ADD_TO_CACHE") {
      const cachedUrls = new Set(result.payload.cachedUrls);
      for (const url of urls) {
        if (!cachedUrls.has(url)) {
          console.warn(`Failed to cache ${url}`);
          return false;
        }
      }
      console.log("Preloaded audios:", urls);
      return true;
    }
    console.warn("Failed to preload audios:", result);
    return false;
  }

  play(trackId?: TrackId) {
    if (!trackId) {
      trackId = usePlayerState.getState().currentTrackId;
    }

    if (this.#locked) {
      console.warn("Player is locked, ignoring play() call", trackId);
      return;
    }
    console.log(`${trackId} play: player locked 🔒`);
    this.#locked = true;

    const { audioElement, sourceNode, gainNode } = this.getTrack(trackId);
    usePlayerState.setState({ currentTrackId: trackId });

    const context = this.getContext(); // a primeira chamada ao getContext deve ser feita em um click handler
    if (!sourceNode || !gainNode) {
      const sourceNode = context.createMediaElementSource(audioElement);
      const gainNode = context.createGain();
      const volume = useMeuPlayState.getState().volume / 100;
      this.setVolume(audioElement, gainNode, volume);
      sourceNode.connect(gainNode);
      gainNode.connect(context.destination);
      console.log(trackId, "connected to AudioContext");
      usePlayerState.setState(
        produce((state: PlayerState) => {
          state.tracks[trackId].sourceNode = sourceNode;
          state.tracks[trackId].gainNode = gainNode;
        }),
      );
    }

    audioElement.play().catch((error) => {
      console.error(trackId, error);
      const errorName = (error as Error)?.name;
      if (
        errorName === "NotSupportedError" ||
        errorName === "NotAllowedError"
      ) {
        setTimeout(() => {
          this.setError(trackId, errorName);
        }, 1_000);
      }
    });
  }

  setError(trackId: TrackId, statusText: string) {
    this.unlock(`${trackId} ${statusText}`);
    usePlayerState.setState(
      produce((state: PlayerState) => {
        if (state.tracks[trackId]) {
          state.tracks[trackId].status = "error";
          state.tracks[trackId].statusText = statusText;
          if (state.tracks[trackId].log.at(-1) !== "error") {
            state.tracks[trackId].log.push("error");
          }
        }
      }),
    );
  }

  pause(trackId?: TrackId) {
    if (!trackId) {
      trackId = usePlayerState.getState().currentTrackId;
    }
    try {
      const { audioElement, isStreaming } = this.getTrack(trackId);
      audioElement.pause();
      if (isStreaming) {
        this.removeTrack(trackId);
      }
    } catch {
      // same as paused, do nothing
      return;
    }
  }

  updateVolume() {
    const volume = useMeuPlayState.getState().volume / 100;
    const { tracks } = usePlayerState.getState();
    for (const trackId in tracks) {
      const { audioElement, gainNode } = tracks[trackId];
      if (gainNode) {
        this.setVolume(audioElement, gainNode, volume);
      }
    }
  }

  /** 0 a 1 */
  setVolume(
    audioElement: HTMLAudioElement,
    gainNode: GainNode,
    volume: number,
  ) {
    if (isSafari) {
      audioElement.volume = volume;
    } else {
      gainNode.gain.value = volume;
    }
  }

  /** 0 a 1 */
  getVolume(audioElement: HTMLAudioElement, gainNode: GainNode) {
    if (isSafari) {
      return audioElement.volume;
    } else {
      return gainNode.gain.value;
    }
  }

  /** 0 a 100 */
  getMaxVolume() {
    return useMeuPlayState.getState().volume;
  }

  async fadeOut(trackId: TrackId, targetVolumePercentage = 5, intervalMs = 4) {
    const { audioElement, gainNode } = this.getTrack(trackId);
    const volume = this.getVolume(audioElement, gainNode!) * 100;
    const targetVolume = volume * (targetVolumePercentage / 100);
    const decrement = (volume - targetVolume) / 100;
    console.log(
      `Fading out ${trackId} from volume ${volume.toFixed(2)} to ${targetVolume.toFixed(2)} decrementing by ${decrement.toFixed(2)}`,
    );
    return new Promise<void>((resolve) => {
      const volumeDown = (v0_100: number) => {
        this.setVolume(audioElement, gainNode!, v0_100 / 100);
        if (v0_100 > targetVolume) {
          setTimeout(() => {
            volumeDown(v0_100 - decrement);
          }, intervalMs);
        } else {
          resolve();
        }
      };
      const volume = this.getVolume(audioElement, gainNode!);
      volumeDown(volume * 100);
    });
  }

  async fadeIn(trackId: TrackId, fromCurrentVolume: boolean, intervalMs = 4) {
    const { audioElement, gainNode } = this.getTrack(trackId);
    if (!fromCurrentVolume) {
      // from zero volume
      this.setVolume(audioElement, gainNode!, 0);
    }
    const volume = this.getVolume(audioElement, gainNode!) * 100;
    const targetVolume = this.getMaxVolume();
    const increment = (targetVolume - volume) / 100;
    console.log(
      `Fading in ${trackId} to volume ${targetVolume.toFixed(2)} incrementing by ${increment.toFixed(2)}`,
    );
    return new Promise<void>((resolve) => {
      const volumeUp = (v0_100: number) => {
        this.setVolume(audioElement, gainNode!, v0_100 / 100);
        if (v0_100 < targetVolume) {
          setTimeout(() => {
            volumeUp(v0_100 + increment);
          }, intervalMs);
        } else {
          resolve();
        }
      };
      const volume = this.getVolume(audioElement, gainNode!);
      volumeUp(volume * 100);
    });
  }

  seekToLastFiveSeconds(trackId: TrackId) {
    const { audioElement } = this.getTrack(trackId);
    if (
      audioElement.duration &&
      !Number.isNaN(audioElement.duration) &&
      audioElement.duration !== Infinity &&
      audioElement.duration > 5
    ) {
      audioElement.currentTime = audioElement.duration - 5;
    } else {
      console.log(
        `Fail to seek ${trackId} to last 5 seconds. Audio duration:`,
        audioElement.duration,
      );
    }
  }
}
