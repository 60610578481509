import { useLoginMutation } from "@src/data/mutations/login";
import type { LoginFormData } from "@src/types";
import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@src/components/Button";
import { Checkbox } from "@src/components/Checkbox";
import { FormGroup } from "@src/components/FormGroup";
import { Input } from "@src/components/Input";
import { Loader } from "@src/components/Loader";

import { useStateWithQueryString } from "@src/hooks/useStateWithQueryString";

import { useMeuPlayState } from "@src/states/meuplay";

import { formDataToObject } from "@src/utils/formDataToObject";
import { formatCnpj } from "@src/utils/formatCnpj";

import "./style.less";

export function Login() {
  const [liteMode, setLiteMode] = useStateWithQueryString("0", "lite");

  const login = useLoginMutation();

  // Atualiza a rádio a partir do loginFormData
  const updated = useRef(false);
  const { loginFormData, radio } = useMeuPlayState();
  const navigate = useNavigate();
  const { search } = useLocation();
  useEffect(() => {
    if (updated.current) {
      // Já atualizou a rádio
      if (loginFormData && radio) {
        const url = radio.require_login
          ? `/${radio.folder}/${loginFormData.cnpj}${search}`
          : `/${radio.folder}${search}`;
        console.log("[Login] Navegando para:", url);
        navigate(url, { replace: true, state: { updated: true } });
      }
      return;
    }
    updated.current = true;
    if (loginFormData && radio) {
      login.mutateAsync(loginFormData).then(
        (updatedRadio) => {
          useMeuPlayState.setState({ radio: updatedRadio });
          console.log("[Login] Rádio atualizada para:", updatedRadio);

          login.reset(); // login.isPending = false;
        },
        (error) => {
          console.log("[Login] Falha ao atualizar a rádio:", error);
        },
      );
    }
  }, [loginFormData, radio, login, navigate, search]);

  const submitForm = useCallback(
    (form: HTMLFormElement) => {
      const loginFormData = formDataToObject<LoginFormData>(new FormData(form));
      if (Object.keys(loginFormData).length === 0) {
        return;
      }

      // Remove non-numeric characters from CNPJ
      loginFormData.cnpj = loginFormData.cnpj.replace(/\D/g, "");

      login.mutateAsync(loginFormData).then(
        (radio) => {
          useMeuPlayState.setState({ loginFormData, radio });
          if (radio) {
            console.log("[Login] Rádio definida como:", radio);
          }
        },
        (error) => {
          console.log("[Login] Falha ao definir a rádio:", error);
        },
      );
    },
    [login],
  );

  // Caso de uso: ao tentar entrar em /recifeinterna
  // já estando logado como /wishpiscina/07687928000645
  const { state } = useLocation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const submitted = useRef(false);
  useEffect(() => {
    if (submitted.current) return;
    submitted.current = true;

    setTimeout(() => {
      if (formRef.current && state?.autoSubmit) {
        console.log("autoSubmit");
        submitForm(formRef.current);
      }
    });
  }, [submitForm, state?.autoSubmit]);

  return (
    <div className="Login-page">
      <video
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        controlsList="nodownload"
      >
        <source
          src="https://www.fabriccamultimidia.com.br/videos/bg-video-escuro.mp4"
          type="video/mp4"
        />
      </video>
      <form
        ref={formRef}
        className="card"
        onSubmit={(event) => {
          event.preventDefault();
          submitForm(event.currentTarget);
        }}
      >
        <img
          src="/logo.png"
          alt="Fabricca Multimídia"
          title="Fabricca Multimídia"
          className="logo"
        />
        <fieldset disabled={login.isPending}>
          <FormGroup>
            <label htmlFor="idRadio">Nome da rádio</label>
            <Input
              id="idRadio"
              type="text"
              name="idRadio"
              defaultValue={state?.idRadio}
              required
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="cnpj">CNPJ</label>
            <Input
              id="cnpj"
              type="text"
              name="cnpj"
              autoComplete="username"
              defaultValue={formatCnpj(state?.cnpj || "")}
              onChange={(event) => {
                const element = event.currentTarget;
                element.value = formatCnpj(element.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="senha">Senha</label>
            <Input
              id="senha"
              type="password"
              name="senha"
              autoComplete="current-password"
              defaultValue={state?.senha}
            />
          </FormGroup>

          <label className="lite-mode">
            <Checkbox
              checked={liteMode === "1"}
              onChange={(event) =>
                setLiteMode(event.target.checked ? "1" : "0")
              }
            />
            Modo Lite
          </label>

          <Button type="submit" disabled={login.isPending}>
            Logar
          </Button>
          {login.isError && (
            <span className="error">{login.error.message}</span>
          )}
          <Loader visible={login.isPending} />
          <a href="/portal">Portal de Links</a>
        </fieldset>
      </form>
    </div>
  );
}
