import { env } from "@src/env";
import type { LoginFormData, Radio } from "@src/types";
import { useMutation } from "@tanstack/react-query";

export function useLoginMutation() {
  return useMutation<Radio, Error, LoginFormData>({
    mutationKey: ["login"],
    mutationFn: async ({ idRadio, cnpj, senha }) => {
      if (!idRadio) {
        return;
      }
      const headers: HeadersInit = { "Content-Type": "application/json" };
      if (cnpj && senha) {
        const encodedCredentials = btoa(`${cnpj}:${senha}`);
        headers["Authorization"] = `Basic ${encodedCredentials}`;
      }
      const response = await fetch(`${env.host}/api/radio/${idRadio}`, {
        method: "GET",
        headers,
      });
      const data = await response.json();
      if (!response.ok) {
        const messages: Record<string, string> = {
          data_not_found: `Rádio ${idRadio} não encontrada`,
          disabled_radio: `Rádio ${idRadio} inativa`,
          incorrect_login_or_password: "Login e/ou senha incorretos",
          login_and_password_required: "Informe login e senha",
        };
        const message = messages[data.error] ?? "Erro desconhecido";
        throw new Error(message);
      }
      return data;
    },
    onError: console.log,
  });
}
