import { useQuery } from "@tanstack/react-query";

import { factory } from "@src/services/radioco";

import { getSlug } from "@src/utils/getSlug";

export function useRequestableTracksQuery(
  streaming_id: string,
  search: string,
) {
  const { data, ...props } = useQuery({
    enabled: false, // aguarda chamar o refetch
    queryKey: ["requestable_tracks", streaming_id],
    queryFn: async () => {
      const radioco = factory(streaming_id);
      const tracks = await radioco.getRequestableTracks();
      return tracks;
    },
  });

  const searchSlug = getSlug(search);
  return {
    data: (data || []).filter((track) => track.keywords.includes(searchSlug)),
    ...props,
  };
}
