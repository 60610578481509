import type { QueryClient } from "@tanstack/react-query";

import { useMeuPlayState } from "@src/states/meuplay";
import { useOfflineModeState } from "@src/states/offline_mode";
import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";
import { DEFAULT_TRACK_ID } from "@src/services/player/constants";

import { enterOfflineMode } from "@src/utils/offline/enterOfflineMode";

export function setupOnlineMode(queryClient: QueryClient) {
  const { radio } = useMeuPlayState.getState();
  if (!player.hasTrack(DEFAULT_TRACK_ID) && radio) {
    player.addTrack(DEFAULT_TRACK_ID, radio.url, true);
    console.log(
      'Setting up "ended" and "error" event listener for main track (radio)',
    );

    player.setExtraEventListener(DEFAULT_TRACK_ID, "ended", () => {
      const { isOnline } = useOfflineModeState.getState();
      const { onAir } = usePlayerState.getState();
      if (!isOnline) {
        console.log("Main track (radio) has ended. The device is offline.");
        enterOfflineMode(queryClient);
      } else if (!onAir) {
        console.log("Main track (radio) has ended. The server is offline.");
        enterOfflineMode(queryClient);
      } else {
        console.log(
          "Main track (radio) has ended. The device and the server is online. Playing again...",
        );
        player.unlock('"ended" event listener for main track');
        player.resetTrack(DEFAULT_TRACK_ID).then(() => {
          player.play(DEFAULT_TRACK_ID);
        });
      }
    });

    let sequentialAttempts = 0;
    player.setExtraEventListener(DEFAULT_TRACK_ID, "error", () => {
      const { isOnline } = useOfflineModeState.getState();
      const { onAir, currentTrackId } = usePlayerState.getState();
      const isOnlinePlaying = currentTrackId === DEFAULT_TRACK_ID;
      if (
        isOnline &&
        isOnlinePlaying &&
        player.getPlayingOrPaused(currentTrackId) === "playing"
      ) {
        if (onAir) {
          const { audioElement } = player.getTrack(DEFAULT_TRACK_ID);
          sequentialAttempts++;
          if (sequentialAttempts <= 3) {
            console.log(
              `Radio on air, resetting due to error:`,
              audioElement.error,
            );
            player.unlock('"error" event listener for main track');
            player.resetTrack(DEFAULT_TRACK_ID).then(() => {
              player.play(DEFAULT_TRACK_ID);
            });
          } else {
            console.warn(
              `Failed to play radio ${sequentialAttempts} times, entering offline mode`,
            );
            sequentialAttempts = 0;
            enterOfflineMode(queryClient);
          }
        } else {
          console.log("Radio off air, entering offline mode");
          sequentialAttempts = 0;
          enterOfflineMode(queryClient);
        }
      }
    });
  }
}
