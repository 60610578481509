import { create } from "zustand";

export type Color = "red" | "yellow" | "green";

export type OfflineModeState = {
  enableConnectionMonitor: boolean;
  isOnline: boolean;
  cachedUrls: Set<string>;
  playedUrls: Set<string>;
  error: string;
  color: Color;
};

export function getInitialOfflineModeState(): OfflineModeState {
  return {
    enableConnectionMonitor: true,
    isOnline: window.navigator.onLine,
    cachedUrls: new Set(),
    playedUrls: new Set(),
    error: "",
    color: "red",
  };
}

export const useOfflineModeState = create<OfflineModeState>()(
  getInitialOfflineModeState,
);
