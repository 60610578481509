import { useLoginMutation } from "@src/data/mutations/login";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { BrowserSupport } from "@src/components/BrowserSupport";

import { useMeuPlayState } from "@src/states/meuplay";

import { storage } from "@src/services/storage";

import { getIdRadio } from "@src/utils/getIdRadio";
import { getRandom } from "@src/utils/getRandom";

import "./style.less";

export function MeuPlay() {
  const login = useLoginMutation();

  const location = useLocation();
  useEffect(() => {
    const idRadio = getIdRadio();
    if (!idRadio) return;

    // Storage namespace
    const name = `MeuPlayState-${idRadio}`;
    if (useMeuPlayState.persist.getOptions().name !== name) {
      const state = useMeuPlayState.getState();
      useMeuPlayState.persist.setOptions({ name });

      storage.clear();
      useMeuPlayState.setState(state);

      console.log("new MeuPlayState storage name:", name);
    } else {
      console.log("MeuPlayState storage name:", name);
    }

    // PWA manifest
    if (document) {
      const manifest = document.querySelector('link[rel="manifest"]');
      const href = `/manifest.json?referer=${encodeURIComponent(window.location.href)}`;
      if (manifest) {
        manifest.setAttribute("href", href);
      } else {
        const link = document.createElement("link");
        link.rel = "manifest";
        link.href = href;
        document.head.appendChild(link);
      }
    }

    // Updates the radio schedule in the background
    const minMinute = 5;
    const maxMinute = 10;
    const intervalMs = getRandom(minMinute * 60 * 1000, maxMinute * 60 * 1000);
    const intervalMin = (intervalMs / 60000).toFixed(2);
    console.log(
      `The schedule will be updated automatically every ${intervalMin} minutes. Type window.checkRadio() to trigger it immediately.`,
    );
    window.checkRadio = function checkRadio() {
      console.log("checkRadio() called!");
      const { loginFormData, radio } = useMeuPlayState.getState();
      if (loginFormData && radio) {
        login.mutateAsync(loginFormData).then(
          (updatedRadio) => {
            useMeuPlayState.setState({ radio: updatedRadio });
            console.log("[checkRadio] Rádio atualizada para:", updatedRadio);
            login.reset(); // login.isPending = false;
          },
          (error) => {
            console.log("[checkRadio] Falha ao atualizar a rádio:", error);
          },
        );
      }
    };
    const interval = setInterval(window.checkRadio, intervalMs);
    return () => {
      clearInterval(interval);
    };
  }, [location.pathname, login]);

  return (
    <div className="MeuPlay-template">
      <BrowserSupport />
      <Outlet />
    </div>
  );
}
