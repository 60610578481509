import debounce from "debounce";
import { RefObject, useEffect, useRef } from "react";

import { useOfflineModeState } from "@src/states/offline_mode";

import { installServiceWorker } from "./installServiceWorker";
import type { ServiceWorkerProps } from "./types";

function _installSW(lastInstallFor: RefObject<string>, idRadio: string) {
  if (lastInstallFor.current === idRadio) return;
  lastInstallFor.current = idRadio;

  console.log("[installSW] Service Worker idRadio:", idRadio);
  installServiceWorker(
    idRadio,
    (sw) => {
      console.log("Service Worker is active", sw);
      useOfflineModeState.setState({ error: "" });
    },
    () => {
      useOfflineModeState.setState({
        error: "Modo Offline Indisponível (A)",
      });
    },
  );
}
const installSW = debounce(_installSW, 500, { immediate: true });

export function ServiceWorker({ radio }: ServiceWorkerProps) {
  const lastInstallFor = useRef(""); // idRadio

  useEffect(() => {
    installSW(lastInstallFor, radio.folder);
  }, [radio.folder]);

  return <></>;
}
