import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useMeuPlayState } from "@src/states/meuplay";

import { logout } from "@src/utils/logout";

export function useRadio() {
  const autoSubmit = useRef(true);
  const { idRadio, cnpj, senha } = useParams();
  const { radio, loginFormData } = useMeuPlayState();
  const navigate = useNavigate();

  const { state, pathname, search } = useLocation();
  useEffect(() => {
    if (state?.updated) {
      const url = pathname + search;
      console.log("[useRadio] Rádio validada, navegando para:", url);
      useMeuPlayState.setState({ isValid: true });
      navigate(url, { replace: true }); // state.updated = false
    } else {
      const url = "/" + search;
      console.log("[useRadio] Validando rádio, navegando para:", url);
      navigate(url, {
        state: {
          autoSubmit: autoSubmit.current,
          idRadio,
          cnpj,
          senha,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRadio, cnpj, senha, pathname, navigate]);

  // Valida o idRadio e CNPJ
  useEffect(() => {
    if (
      idRadio !== radio?.folder ||
      idRadio !== loginFormData?.idRadio ||
      (cnpj && cnpj !== loginFormData?.cnpj) ||
      (senha && senha !== loginFormData?.senha)
    ) {
      logout();
      const url = "/" + search;
      console.log("[useRadio] Trocou de rádio, navegando para:", url);
      navigate(url, {
        state: {
          autoSubmit: autoSubmit.current,
          idRadio,
          cnpj,
          senha,
        },
      });
    }
  }, [
    idRadio,
    cnpj,
    senha,
    radio?.folder,
    loginFormData?.idRadio,
    loginFormData?.cnpj,
    loginFormData?.senha,
    navigate,
    search,
  ]);

  return {
    idRadio,
    cnpj,
    radio,
    logout: () => {
      autoSubmit.current = false;
      logout();
    },
  };
}
