import slugify from "slugify";

export function getSlug(str: string): string {
  return slugify(str, {
    replacement: " ",
    lower: true,
    locale: "pt",
    remove: /[*+~.()'"!:@]/g,
    trim: true,
  });
}
