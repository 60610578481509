import { useEffect, useRef } from "react";

import "./style.less";
import type { BackgroundProps } from "./types";

export function Background({ url }: BackgroundProps) {
  const blurRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setTimeout(() => {
        if (blurRef.current) {
          blurRef.current.classList.add("visible");
        }
      }, 100);
    };
  }, [url]);

  return (
    <div className="Background-component">
      <div
        ref={blurRef}
        className="blur"
        style={{ backgroundImage: `url(${url})` }}
      />
    </div>
  );
}
