export function getRandom(min: number, max: number, avoid?: number): number {
  if (min > max) return min;
  if (min === max && min === avoid) return avoid;

  let randomNumber: number;
  do {
    randomNumber = Math.round((max - min) * Math.random()) + min;
  } while (randomNumber === avoid && max !== min);
  return randomNumber;
}
