import type { EventName, PlayerStatus } from "@src/types";

export const DEFAULT_TRACK_ID = "radio";

// https://developer.mozilla.org/en-US/docs/Web/Media/Audio_and_video_delivery/Cross-browser_audio_basics
export const STATUS_MAP: Record<
  EventName,
  { enabled: boolean; description: string; status: PlayerStatus | "auto" }
> = {
  abort: {
    enabled: true,
    description: "O carregamento do áudio foi interrompido.",
    status: "paused",
  },
  canplay: {
    enabled: true,
    description:
      "O áudio pode começar a ser reproduzido, mas pode haver mais dados para carregar.",
    status: "auto",
  },
  canplaythrough: {
    enabled: true,
    description:
      "O áudio pode ser reproduzido até o fim sem parar para carregar mais dados.",
    status: "auto",
  },
  durationchange: {
    enabled: false,
    description: "A duração do áudio foi alterada.",
    status: "loading",
  },
  emptied: {
    enabled: true,
    description: "O recurso de mídia foi esvaziado.",
    status: "paused",
  },
  ended: {
    enabled: true,
    description: "A reprodução chegou ao fim.",
    status: "paused",
  },
  error: {
    enabled: true,
    description:
      "Ocorreu um erro durante o carregamento ou reprodução do áudio.",
    status: "error",
  },
  loadstart: {
    enabled: true,
    description: "O processo de carregamento começou.",
    status: "loading",
  },
  loadeddata: {
    enabled: true,
    description: "Os primeiros dados do áudio foram carregados.",
    status: "loading",
  },
  loadedmetadata: {
    enabled: true,
    description: "Os metadados do áudio foram carregados.",
    status: "auto",
  },
  pause: {
    enabled: true,
    description: "A reprodução foi pausada.",
    status: "paused",
  },
  play: {
    enabled: true,
    description: "A reprodução começou ou foi retomada.",
    status: "playing",
  },
  playing: {
    enabled: true,
    description:
      "A reprodução começou ou foi retomada e o áudio está sendo reproduzido.",
    status: "playing",
  },
  progress: {
    enabled: false,
    description: "O carregamento do áudio está em andamento.",
    status: "loading",
  },
  ratechange: {
    enabled: false,
    description: "A velocidade de reprodução do áudio foi alterada.",
    status: "loading",
  },
  seeked: {
    enabled: true,
    description: "A busca para uma nova posição foi concluída.",
    status: "loading",
  },
  seeking: {
    enabled: true,
    description: "A busca para uma nova posição começou.",
    status: "loading",
  },
  stalled: {
    enabled: true,
    description: "O carregamento de dados foi interrompido.",
    status: "error",
  },
  suspend: {
    enabled: false,
    description: "O carregamento foi suspenso.",
    status: "paused",
  },
  timeupdate: {
    enabled: false,
    description: "O tempo atual de reprodução do áudio foi atualizado.",
    status: "loading",
  },
  volumechange: {
    enabled: false,
    description: "O volume do áudio foi alterado.",
    status: "loading",
  },
  waiting: {
    enabled: true,
    description: "A reprodução está parada, pois precisa carregar mais dados.",
    status: "loading",
  },
};
