import { devMode } from "@src/env";

import { Artwork } from "@src/components/Artwork";
import { Background } from "@src/components/Background";
import { Debug } from "@src/components/Debug";
import { Footer } from "@src/components/Footer";
import { LoginInfo } from "@src/components/LoginInfo";
import { NextTrack } from "@src/components/NextTrack";
import { OfflineModeIndicator } from "@src/components/OfflineModeIndicator";
import { PreviousTracks } from "@src/components/PreviousTracks";
import { ServiceWorker } from "@src/components/ServiceWorker";
import { SocialMedia } from "@src/components/SocialMedia";
import { StreamInfo } from "@src/components/StreamInfo";
import { Version } from "@src/components/Version";

import { usePlayer } from "@src/hooks/usePlayer";

import "./style.less";

export function Player() {
  const { radio, logout, currentTrack, isOnlinePlaying } = usePlayer();

  if (!radio) {
    return <></>;
  }

  return (
    <div className="Player-page">
      <ServiceWorker radio={radio} />
      <Background url={currentTrack.artwork_large} />
      <main>
        <OfflineModeIndicator radio={radio} />
        <Version idRadio={radio.folder} />
        <LoginInfo logged_in_as={radio.radio_name} logout={logout} />
        <SocialMedia />
        <PreviousTracks />
        <Artwork currentTrack={currentTrack} />
        <NextTrack />
        {isOnlinePlaying && <StreamInfo radio={radio} />}
        {devMode && <Debug radio={radio} />}
      </main>
      <Footer radio={radio} />
    </div>
  );
}
