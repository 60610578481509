import type { MessageFromServiceWorker } from "@src/types";

import { useOfflineModeState } from "@src/states/offline_mode";

import { exchangeMessage } from "@src/utils/serviceWorker/exchangeMessage";

export async function renewPlayedUrls(
  idRadio: string,
  afterRemoveFromCache: (eventData: MessageFromServiceWorker) => void,
) {
  const { playedUrls, cachedUrls } = useOfflineModeState.getState();
  const _playedUrls = Array.from(playedUrls).slice(0, -1);
  const toRemoveFromCache = _playedUrls.filter((item) => cachedUrls.has(item));
  if (toRemoveFromCache.length === 0) {
    // console.log("There is no played offline URLs to renew");
    return;
  }

  console.log(`Renewing ${toRemoveFromCache.length} played offline URLs`);
  exchangeMessage({
    idRadio,
    type: "REMOVE_FROM_CACHE",
    payload: {
      cacheName: "offlineAudios",
      urls: _playedUrls,
    },
  }).then(afterRemoveFromCache);
}
