import { create } from "zustand";

import { isPwa } from "@src/utils/isPwa";

import type { BeforeInstallPromptEvent } from "./types";

export type InstallPwaState = {
  deferredPrompt?: BeforeInstallPromptEvent;
  installed: boolean;
};

export const useInstallPwaState = create<InstallPwaState>()(() => ({
  deferredPrompt: undefined,
  installed: isPwa(),
}));
