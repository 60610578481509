import type { Audio } from "@src/types";
import { create } from "zustand";

type CronAudio = Audio & {
  image: string;
};

export type CronState = {
  playingId?: string;
  cronQueue: Set<CronAudio>;
};

export function getInitialCronState(): CronState {
  return {
    playingId: undefined,
    cronQueue: new Set(),
  };
}

export const useCronState = create<CronState>()(getInitialCronState);
