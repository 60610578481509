import clsx from "clsx";
import { useState } from "react";
import { IconContext } from "react-icons";
import { FiChevronsRight } from "react-icons/fi";
import { Tooltip } from "react-tooltip";

import { TrackInfo } from "@src/components/TrackInfo";

import { usePlayerState } from "@src/states/player";

import { isMobile } from "@src/utils/isMobile";

import "./style.less";

export function PreviousTracks() {
  const [opened, setOpened] = useState(false);
  const trackHistory = usePlayerState((state) => state.trackHistory);
  return (
    <IconContext.Provider
      value={{ color: "#ffffff", className: "icon", size: "2em" }}
    >
      <section className={clsx("PreviousTracks-component", { opened })}>
        <Tooltip
          id="previous-tracks"
          disableTooltip={() => isMobile}
          style={{ zIndex: 4 }}
        />
        <div
          className="toggle"
          onClick={() => setOpened((state) => !state)}
          data-tooltip-id="previous-tracks"
          data-tooltip-content="Última tocadas"
          data-tooltip-place="right"
        >
          <FiChevronsRight />
        </div>
        <div className="content">
          {trackHistory.map((track) => (
            <TrackInfo
              key={track.datetime}
              title={track.title}
              artist={track.artist}
              image={track.artwork_small}
              datetime={track.datetime}
            />
          ))}
        </div>
      </section>
    </IconContext.Provider>
  );
}
