import "./style.less";
import type { PortalDesktopProps } from "./types";

export function PortalDesktop({ links }: PortalDesktopProps) {
  const folders = Object.keys(links);

  if (folders.length === 0) {
    return <></>;
  }

  return (
    <table border={1} className="PortalDesktop-component">
      <thead>
        <tr>
          <th>Nome da rádio</th>
          <th>
            Recomendado
            <br />
            Full
          </th>
          <th>
            Alternativo
            <br />
            Full
          </th>
          <th>
            Recomendado
            <br />
            Lite
          </th>
          <th>
            Alternativo
            <br />
            Lite
          </th>
        </tr>
      </thead>
      <tbody>
        {folders.map((folder) => (
          <tr key={folder}>
            <th>{folder}</th>
            <td>
              <a href={links[folder].recommended} target="_blank">
                Link
              </a>
            </td>
            <td>
              <a href={links[folder].alternative} target="_blank">
                Link
              </a>
            </td>
            <td>
              <a href={links[folder].recommended_lite} target="_blank">
                Link
              </a>
            </td>
            <td>
              <a href={links[folder].alternative_lite} target="_blank">
                Link
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
