import { devMode, env, version } from "@src/env";

import { getTimestampInSeconds } from "@src/utils/getTimestampInSeconds";

import type {
  AudioLinksResponse,
  FrontendVersionResponse,
  HeadersResponse,
} from "./types";

export class Api {
  static async getAudioLinks(idRadio: string): Promise<string[]> {
    return new Promise((resolve) => {
      const url = `${env.host}/api/audio-links/${idRadio}`;
      fetch(url)
        .then((response) => response.json())
        .then((response: AudioLinksResponse) => {
          resolve(response.links);
        });
    });
  }

  static async getFrontendVersion(): Promise<string> {
    return new Promise((resolve) => {
      // Development
      if (devMode) {
        resolve(version);
        return;
      }

      // Production
      const cacheBuster = getTimestampInSeconds();
      const url = `${env.api}/frontend-version?cache_buster=${cacheBuster}`;
      fetch(url)
        .then((response) => response.json())
        .then(({ success, response }: FrontendVersionResponse) => {
          if (success) {
            resolve(response.version || version);
          }
        });
    });
  }

  static async getHeaders(fromUrl: string): Promise<Record<string, string>> {
    return new Promise((resolve) => {
      const url = `${env.api}/headers?url=${encodeURIComponent(fromUrl)}`;
      fetch(url)
        .then((response) => response.json())
        .then(({ success, response }: HeadersResponse) => {
          if (success) {
            resolve(response.headers);
          }
        });
    });
  }
}
