import { type ReactNode, useEffect, useMemo, useState } from "react";
import { IconContext } from "react-icons";
import { FaFileAudio } from "react-icons/fa6";
import { PiPlayCircleFill } from "react-icons/pi";

import { Modal } from "@src/components/Modal";

import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";

import { createImage } from "@src/utils/createImage";

import "./style.less";
import type { AudiosOnDemandProps } from "./types";

export function AudiosOnDemand({ radio }: AudiosOnDemandProps) {
  const [isOpen, setIsOpen] = useState(false);
  const audios = useMemo(
    () => radio.audios.filter((audio) => !audio.cron),
    [radio],
  );

  useEffect(() => {
    const urls = audios.map((audio) => audio.url);
    if (urls.length > 0) {
      console.log("[AudiosOnDemand] Preloading audios:", urls);
      player.preloadAudios(radio.folder, urls);
    }
  }, [audios, radio.folder]);

  let renderList: ReactNode = null;
  if (audios.length === 0) {
    renderList = <p className="empty">Nenhum áudio não agendado disponível.</p>;
  } else {
    renderList = audios.map((item) => {
      const image = createImage(item.name);
      return (
        <div
          key={item.id}
          className="audio clickable"
          onClick={() => {
            player.playWithFade(
              radio.folder,
              "onDemand",
              [item.url],
              () => {
                // onPlaying
                setIsOpen(false);
                usePlayerState.setState({
                  currentTrackId: "onDemand",
                  currentTrack: {
                    artist: "Áudio não agendado",
                    title: item.name,
                    artwork_small: image,
                    artwork_large: image,
                  },
                });
              },
              () => {
                // onEnded
                console.log("onDemand ended");
              },
            );
          }}
        >
          <img src={image} alt={item.name} />
          <IconContext.Provider
            value={{ color: "#ff5510", className: "icon", size: "4em" }}
          >
            <PiPlayCircleFill className="play" />
          </IconContext.Provider>
          <div className="text">{item.name}</div>
        </div>
      );
    });
  }

  return (
    <>
      <IconContext.Provider
        value={{ color: "#ffffff", className: "icon", size: "1.5em" }}
      >
        <div
          className="AudiosOnDemand-component clickable"
          onClick={() => setIsOpen(true)}
          data-tooltip-id="footer"
          data-tooltip-html="Áudios<br>não agendados"
          data-tooltip-class-name="text-center"
          data-tooltip-place="top"
        >
          <FaFileAudio />
        </div>
      </IconContext.Provider>
      <Modal
        className="AudiosOnDemand-modal"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <h2>Áudios não agendados</h2>
        <hr />
        <div className="list">{renderList}</div>
      </Modal>
    </>
  );
}
