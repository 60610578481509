import { browserName, isSupported } from "@src/utils/browser";

import "./style.less";

export function BrowserSupport() {
  if (isSupported) {
    return <></>;
  }

  return (
    <div className="BrowserSupport-component">
      ⚠️ Navegador {browserName} não homologado. O player pode não funcionar
      adequadamente. Utilize Chrome, Edge, Firefox ou Safari.
    </div>
  );
}
