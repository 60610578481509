/* eslint-disable @typescript-eslint/no-unused-vars */
import { usePlayerState } from "@src/states/player";

import "./style.less";
import type { DebugProps } from "./types";

// @ts-expect-error debug
export function Debug({ radio }: DebugProps) {
  const { currentTrack, nextTrack, trackHistory, ...state } = usePlayerState();
  return (
    <div className="Debug-component">
      <pre>{JSON.stringify(state, null, 2)}</pre>
    </div>
  );
}
