import * as Sentry from "@sentry/react";
import { version as currentVersion, devMode } from "@src/env";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { Button } from "@src/components/Button";
import { Modal } from "@src/components/Modal";

import { Api } from "@src/services/api";

import { destroyCache } from "@src/utils/serviceWorker/destroyCache";
import { setQueryString } from "@src/utils/setQueryString";

import "./style.less";
import type { VersionProps } from "./types";

function updateVersion(idRadio: string) {
  destroyCache(idRadio, "DESTROY_PRECACHE").then(() => {
    window.location.href = setQueryString(
      window.location.href,
      "autoplay",
      "1",
    );
  });
}

export function Version({ idRadio, hasServiceWorker = true }: VersionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [recording, setRecording] = useState(false);

  const { data } = useQuery({
    queryKey: ["latestVersion"],
    queryFn: Api.getFrontendVersion,
    refetchInterval: 30_000,
  });
  const latestVersion = data || currentVersion;
  const hasUpdate = latestVersion !== currentVersion;

  const willUpdate = useRef(false);
  useEffect(() => {
    if (hasUpdate && !willUpdate.current) {
      willUpdate.current = true;
      const delay = 1 * 60 * 1_000; // 1 minute
      toast.warn(
        `O player será atualizado da versão ${currentVersion} para a versão ${latestVersion} em 1 minuto.`,
        {
          autoClose: delay,
          draggable: false,
          closeButton: false,
          closeOnClick: false,
        },
      );
      setTimeout(() => updateVersion(idRadio), delay);
    }
  }, [hasUpdate, latestVersion, idRadio]);

  return (
    <div className="Version-component">
      <img
        src="/logo_white.png"
        alt="Fabricca Multimídia"
        title="Fabricca Multimídia"
        className="logo"
      />
      <span className="clickable" onClick={() => setIsOpen(true)}>
        v{currentVersion}
      </span>
      <Modal className="Version-modal" isOpen={isOpen} setIsOpen={setIsOpen}>
        <h2>Suporte</h2>
        <hr />
        <div>
          {hasServiceWorker && (
            <Button onClick={() => updateVersion(idRadio)}>
              {hasUpdate ? `Atualizar para v${latestVersion}` : "Limpar cache"}
            </Button>
          )}
          <Button
            disabled={recording}
            onClick={() => {
              if (devMode) {
                toast.warn(
                  "A integração com o Sentry não está disponível em modo de desenvolvimento.",
                );
              } else {
                const replay = Sentry.getReplay();
                if (replay) {
                  replay.flush();
                  setRecording(true);
                }
              }
            }}
          >
            {recording ? "Monitoramento ativado" : "Ativar monitoramento"}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
