import { useTracksQuery } from "@src/data/queries/tracks";
import { useEffect } from "react";
import { useShallow } from "zustand/shallow";

import { useRadio } from "@src/hooks/useRadio";

import { usePlayerState } from "@src/states/player";

import { DEFAULT_TRACK_ID } from "@src/services/player/constants";

export function usePlayer() {
  const { radio, logout } = useRadio();
  const [currentTrack, currentTrackId] = usePlayerState(
    useShallow((state) => [state.currentTrack, state.currentTrackId]),
  );
  const isOnlinePlaying = currentTrackId === DEFAULT_TRACK_ID;

  const streaming_id = radio?.streaming_id || "";
  const { data: onlineData } = useTracksQuery(streaming_id);
  useEffect(() => {
    if (onlineData) {
      if (!isOnlinePlaying) {
        usePlayerState.setState({
          nextTrack: onlineData.currentTrack,
          trackHistory: onlineData.trackHistory,
          onAir: onlineData.onAir,
        });
      } else {
        usePlayerState.setState({
          nextTrack: onlineData.nextTrack ?? undefined,
          trackHistory: onlineData.trackHistory,
          onAir: onlineData.onAir,
          currentTrack: onlineData.currentTrack,
        });
      }
    }
  }, [isOnlinePlaying, onlineData]);

  return { radio, logout, currentTrack, isOnlinePlaying };
}
