import debounce from "debounce";
import { useEffect } from "react";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";

const values: Record<string, string> = {};
const debounceFn = debounce((fn: () => void) => fn(), 10);

function setValues(setSearchParams: SetURLSearchParams) {
  debounceFn.clear();
  debounceFn(() => {
    setSearchParams(
      (searchParams) => {
        const newSearchParams = {
          ...Object.fromEntries(searchParams),
          ...values,
        };
        for (const key in newSearchParams) {
          if (newSearchParams[key] === "") {
            delete newSearchParams[key];
          }
        }
        return newSearchParams;
      },
      { replace: true },
    );
    for (const prop in values) {
      delete values[prop];
    }
  });
}

export function useStateWithQueryString(
  initialValue: string | number,
  paramName: string,
): readonly [value: string, setValue: (newValue: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const valueFromQueryString = searchParams.get(paramName);

  const value = valueFromQueryString
    ? valueFromQueryString
    : String(initialValue);

  useEffect(() => {
    if (!valueFromQueryString) {
      values[paramName] = value;
      setValues(setSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = (newValue: string) => {
    values[paramName] = newValue;
    setValues(setSearchParams);
  };

  return [value, setValue];
}
