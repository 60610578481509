import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import "./style.less";

export function ErrorBoundary() {
  const error = useRouteError();

  let title = "Algo deu errado";
  let content = "";
  if (isRouteErrorResponse(error)) {
    title = error.status + " " + error.statusText;
    content = error.data;
  } else if (error instanceof Error) {
    title = error.name;
    content = error.message;
  } else if (typeof error === "string") {
    content = error;
  }

  return (
    <div className="ErrorBoundary-template">
      <h1>{title}</h1>
      <p>{content}</p>
    </div>
  );
}
