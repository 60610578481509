import { env, version } from "@src/env";
import type { RadioSession } from "@src/types";
import { useMutation } from "@tanstack/react-query";

import { getDeviceId } from "@src/utils/getDeviceId";

export function useRegisterRadioSessionMutation(method: "POST" | "DELETE") {
  return useMutation<RadioSession, Error, { idRadio: string; cnpj?: string }>({
    mutationKey: ["register_radio_session", method],
    mutationFn: async ({ idRadio, cnpj }) => {
      if (!idRadio) {
        return;
      }

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("X-Requested-With", "XMLHttpRequest");

      const response = await fetch(`${env.api}/radio-session`, {
        method,
        headers,
        body: JSON.stringify({
          id_radio: idRadio,
          cnpj,
          device_id: getDeviceId(),
          player_version: version,
        }),
      });
      const data = await response.json();
      if (!response.ok || !data?.success) {
        throw new Error("Falha ao registrar sessão");
      }
      return data;
    },
    onError: console.log,
  });
}
