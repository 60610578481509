import clsx from "clsx";
import {
  type InputHTMLAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import "./style.less";

type TextareaProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  minHeight?: number;
  maxHeight?: number;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Input(
    { minHeight = 80, maxHeight = 200, className, ...props },
    forwardedRef,
  ) {
    const ref = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(forwardedRef, () => ref.current!);

    function onInput() {
      const textarea = ref.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${Math.max(minHeight, Math.min(textarea.scrollHeight, maxHeight))}px`;
      }
    }

    useEffect(() => {
      onInput();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <textarea
        ref={ref}
        className={clsx("Textarea-component", className)}
        onInput={onInput}
        style={{ resize: "none", overflow: "auto" }}
        {...props}
      />
    );
  },
);
