import { Registry } from "@src/services/registry";

import { RadioCo } from "./radioco";

export { RadioCo };

const registry = new Registry<RadioCo>();

export function factory(streaming_id: string) {
  return registry.factory(streaming_id, () => new RadioCo(streaming_id));
}
