import { useRequestTrackMutation } from "@src/data/mutations/requestTrack";
import { useRequestableTracksQuery } from "@src/data/queries/requestableTracks";
import type { RequestableTrackInfo } from "@src/types";
import debounce from "debounce";
import List from "rc-virtual-list";
import { type ReactNode, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaHandHoldingHeart } from "react-icons/fa";
import { toast } from "react-toastify";

import { Button } from "@src/components/Button";
import { FormGroup } from "@src/components/FormGroup";
import { Input } from "@src/components/Input";
import { Loader } from "@src/components/Loader";
import { Modal } from "@src/components/Modal";
import { TrackInfo } from "@src/components/TrackInfo";

import { formDataToObject } from "@src/utils/formDataToObject";
import { getElementHeight } from "@src/utils/getElementHeight";
import { isMobile } from "@src/utils/isMobile";

import "./style.less";
import type { RequestTrackProps, SearchFormData } from "./types";

const itemHeight = 54 + 8; // 54 + var(--spacing)

const debounceFn = debounce((fn: () => void) => fn(), 500);

function closeKeyboard() {
  document.querySelector<HTMLInputElement>("#query")?.blur();
  if ("virtualKeyboard" in navigator) {
    navigator.virtualKeyboard.hide();
  }
}

export function RequestTrack({ radio }: RequestTrackProps) {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  useEffect(() => {
    debounceFn.clear();
    debounceFn(() => setDebouncedQuery(query));
  }, [query]);

  const streaming_id = radio?.streaming_id || "";
  const requestableTracks = useRequestableTracksQuery(
    streaming_id,
    debouncedQuery,
  );
  const tracks = requestableTracks.data || [];

  const requestTrack = useRequestTrackMutation(streaming_id);
  const _requestTrack = (track: RequestableTrackInfo) => {
    requestTrack.mutateAsync(track.id).then(
      (success) => {
        if (success) {
          console.log("[requestTrack] Música pedida com sucesso:", track);
          requestTrack.reset(); // requestTrack.isPending = false;
          toast.success(
            `A música ${track.artist} - ${track.title} foi incluída na programação.`,
          );
          setIsOpen(false);
        } else {
          console.log("[requestTrack] Falha ao pedir música:", track);
        }
      },
      (error) => {
        console.log("[requestTrack] Falha ao pedir música:", error);
      },
    );
  };

  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      if ("virtualKeyboard" in navigator) {
        navigator.virtualKeyboard.overlaysContent = true;
      }
      requestableTracks.refetch();
      document.documentElement.classList.add("no-refresh");
    } else {
      setQuery("");
      setDebouncedQuery("");
      document.documentElement.classList.remove("no-refresh");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    function onKeyUp(event: KeyboardEvent) {
      const key = event.code || event.keyCode;
      if (key === "Enter" || key === 13) {
        closeKeyboard();
      }
    }
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const isLoading = requestableTracks.isLoading || requestTrack.isPending;
  const isError = requestableTracks.isError || requestTrack.isError;
  const error = requestableTracks.error || requestTrack.error;

  let content: ReactNode;
  if (isLoading) {
    content = <Loader visible={true} />;
  } else if (isError) {
    content = <span className="error">{error?.message}</span>;
  } else {
    content = (
      <>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            closeKeyboard();
            const searchFormData = formDataToObject<SearchFormData>(
              new FormData(event.currentTarget),
            );
            setDebouncedQuery(searchFormData.query);
          }}
        >
          <FormGroup>
            <label htmlFor="query">Buscar</label>
            <div className="row">
              <Input
                id="query"
                type="text"
                name="query"
                autoComplete="off"
                placeholder="Nome da música ou artista"
                value={query}
                onChange={(event) => setQuery(event.currentTarget.value)}
                autoFocus
              />
              {isMobile && <Button type="submit">OK</Button>}
            </div>
          </FormGroup>
        </form>
        <div
          className="list"
          ref={(ref) => {
            if (ref) {
              setHeight(getElementHeight(ref));
            }
          }}
        >
          {height > 0 &&
            (tracks.length ? (
              <List
                data={tracks}
                style={{ width: "inherit" }}
                height={height}
                itemHeight={itemHeight}
                itemKey="id"
              >
                {(track) => (
                  <TrackInfo
                    title={track.title}
                    artist={track.artist}
                    image={track.artwork_small}
                    onClick={() => _requestTrack(track)}
                    style={{ height: itemHeight }}
                  />
                )}
              </List>
            ) : (
              <span className="error">
                {debouncedQuery
                  ? `Nenhuma música foi encontrada com o termo {debouncedQuery}`
                  : "Nenhuma música disponível"}
              </span>
            ))}
        </div>
      </>
    );
  }

  return (
    <>
      <IconContext.Provider
        value={{ color: "#ffffff", className: "icon", size: "1.5em" }}
      >
        <div
          className="RequestTrack-component clickable"
          onClick={() => setIsOpen(true)}
          data-tooltip-id="footer"
          data-tooltip-content="Pedir músicas"
          data-tooltip-place="top"
        >
          <FaHandHoldingHeart />
        </div>
      </IconContext.Provider>
      <Modal
        className="RequestTrack-modal"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <h2>Pedir músicas</h2>
        <hr />
        <div>{content}</div>
      </Modal>
    </>
  );
}
