import { useState } from "react";
import { IconContext } from "react-icons";
import {
  ImVolumeHigh,
  ImVolumeLow,
  ImVolumeMedium,
  ImVolumeMute2,
} from "react-icons/im";

import { InputRange } from "@src/components/InputRange";

import { useMeuPlayState } from "@src/states/meuplay";

import { player } from "@src/services/player";

import { isIos } from "@src/utils/isIos";

import "./style.less";

export function VolumeControl() {
  const [volumeWhenMuted, setVolumeWhenMuted] = useState(0);
  const volume = useMeuPlayState((state) => state.volume);

  let VolumeIcon;
  if (volume === 0) VolumeIcon = ImVolumeMute2;
  if (volume > 0 && volume < 33) VolumeIcon = ImVolumeLow;
  if (volume >= 33 && volume < 66) VolumeIcon = ImVolumeMedium;
  if (volume >= 66) VolumeIcon = ImVolumeHigh;

  if (isIos) {
    return <></>;
  }
  return (
    <IconContext.Provider
      value={{ color: "#ffffff", className: "icon", size: "1.5em" }}
    >
      <div className="VolumeControl-component">
        {VolumeIcon && (
          <VolumeIcon
            size={25}
            className="clickable"
            onClick={() => {
              if (volumeWhenMuted > 0) {
                setVolumeWhenMuted(0);
                useMeuPlayState.setState({ volume: volumeWhenMuted });
              } else {
                setVolumeWhenMuted(volume);
                useMeuPlayState.setState({ volume: 0 });
              }
              player.updateVolume();
            }}
          />
        )}
        <InputRange
          min={0}
          max={100}
          value={volume}
          onChange={(event) => {
            setVolumeWhenMuted(0);
            const newVolume = parseInt(event.currentTarget.value);
            useMeuPlayState.setState({ volume: newVolume });
            player.updateVolume();
          }}
        />
      </div>
    </IconContext.Provider>
  );
}
