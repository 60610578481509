import { type InputHTMLAttributes, forwardRef } from "react";

import "./style.less";

export const Checkbox = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(function Checkbox(props, ref) {
  return (
    <div className="Checkbox-component">
      <input {...props} ref={ref} type="checkbox" />
      <span className="checkmark" />
    </div>
  );
});
