import clsx from "clsx";
import { format } from "date-fns/format";
import { forwardRef, useMemo } from "react";

import "./style.less";
import type { TrackInfoProps } from "./types";

export const TrackInfo = forwardRef<HTMLDivElement, TrackInfoProps>(
  function TrackInfo(
    { className, header, title, artist, image, datetime, onClick, style },
    ref,
  ) {
    const no_header = !header;
    const no_image = !image;
    const no_datetime = !datetime;
    const loading = !title;

    const dateFormatted = useMemo(() => {
      if (!datetime) {
        return "";
      }
      const timeObj = new Date(datetime);
      return format(timeObj, "dd/MM/yy");
    }, [datetime]);

    const timeFormatted = useMemo(() => {
      if (!datetime) {
        return "";
      }
      const timeObj = new Date(datetime);
      return format(timeObj, "HH:mm:ss");
    }, [datetime]);

    return (
      <div
        ref={ref}
        className={clsx(
          "TrackInfo-component",
          { loading, no_image, no_header, no_datetime, clickable: !!onClick },
          className,
        )}
        onClick={onClick}
        style={style}
      >
        {no_header ? <></> : <div className="header">{header}</div>}
        {no_image ? <></> : <img src={image} title={`${artist} - ${title}`} />}
        <div className="col">
          <div className="row1">{title}</div>
          <div className="row2">{artist}</div>
        </div>
        {no_datetime ? (
          <></>
        ) : (
          <div className="col">
            <div className="row1">{timeFormatted}</div>
            <div className="row2">{dateFormatted}</div>
          </div>
        )}
      </div>
    );
  },
);
