import { useMutation } from "@tanstack/react-query";

import { factory } from "@src/services/radioco";

export function useRequestTrackMutation(streaming_id: string) {
  return useMutation({
    mutationKey: ["request_track", streaming_id],
    onMutate: (track_id) => ({
      mutationKey: ["request_track", streaming_id, track_id],
    }),
    mutationFn: async (track_id: number) => {
      const radioco = factory(streaming_id);
      return radioco.requestTrack(track_id);
    },
  });
}
