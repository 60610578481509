import clsx from "clsx";
import { type InputHTMLAttributes, forwardRef } from "react";

import "./style.less";

export const InputRange = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(function InputRange({ className, ...props }, ref) {
  return (
    <input
      {...props}
      ref={ref}
      className={clsx("InputRange-component", className)}
      type="range"
    />
  );
});
