import type {
  MessageFromServiceWorker,
  MessageToServiceWorker,
} from "@src/types";

import { alwaysResolve } from "@src/utils/alwaysResolve";
import { getTimestampInSeconds } from "@src/utils/getTimestampInSeconds";
import { getServiceWorker } from "@src/utils/serviceWorker/getServiceWorker";

export async function exchangeMessage(
  messageToServiceWorker: MessageToServiceWorker,
) {
  const { success, result } = await alwaysResolve(getServiceWorker());
  if (!success) return Promise.reject(result);

  return new Promise<MessageFromServiceWorker>((resolve) => {
    if ("messageId" in messageToServiceWorker) {
      // resposta disponível externamente
    } else {
      // resposta disponível apenas ao remetente
      messageToServiceWorker.messageId =
        messageToServiceWorker.idRadio + "_" + getTimestampInSeconds();
    }
    result.postMessage(messageToServiceWorker);

    const onMessage = function _onMessage(event: MessageEvent) {
      const eventData: MessageFromServiceWorker = event.data;
      if (eventData.originMessageId === messageToServiceWorker.messageId) {
        navigator.serviceWorker.removeEventListener("message", onMessage);
        resolve(eventData);
      }
    };
    navigator.serviceWorker.addEventListener("message", onMessage);
  });
}
