import { TrackInfo } from "@src/components/TrackInfo";

import { usePlayerState } from "@src/states/player";

import "./style.less";

export function NextTrack() {
  const nextTrack = usePlayerState((state) => state.nextTrack);

  if (!nextTrack) {
    return <></>;
  }

  return (
    <TrackInfo
      className="NextTrack-component"
      header="A SEGUIR"
      title={nextTrack.title}
      artist={nextTrack.artist}
      image={nextTrack.artwork_small}
    />
  );
}
