import { useOfflineAudiosQuery } from "@src/data/queries/offlineAudios";
import { devMode } from "@src/env";
import type { MessageFromServiceWorker } from "@src/types";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { TestButtons } from "@src/components/TestButtons";

import { useOfflineModeState } from "@src/states/offline_mode";
import { usePlayerState } from "@src/states/player";

import { alwaysResolve } from "@src/utils/alwaysResolve";
import { checkConnection } from "@src/utils/checkConnection";
import { enterOfflineMode } from "@src/utils/offline/enterOfflineMode";
import { renewPlayedUrls } from "@src/utils/offline/renewPlayedUrls";
import { exchangeMessage } from "@src/utils/serviceWorker/exchangeMessage";
import { getServiceWorker } from "@src/utils/serviceWorker/getServiceWorker";

import "./style.less";
import type { OfflineModeIndicatorProps } from "./types";

export function OfflineModeIndicator({ radio }: OfflineModeIndicatorProps) {
  function afterRemoveFromCache(eventData: MessageFromServiceWorker) {
    if (eventData.type !== "RESPONSE_REMOVE_FROM_CACHE") return;

    const { cachedUrls, removedUrls } = eventData.payload;
    if (removedUrls.length > 0) {
      const avoidUrls = new Set([...cachedUrls, ...removedUrls]);
      offlineAudiosQuery.refetch().then(({ data }) => {
        if (data?.cacheableAudioLinks) {
          const urlsToCache = data.cacheableAudioLinks
            .filter((url) => !avoidUrls.has(url))
            .slice(0, removedUrls.length);
          if (urlsToCache.length > 0) {
            exchangeMessage({
              idRadio: radio.folder,
              type: "ADD_TO_CACHE",
              payload: {
                cacheName: "offlineAudios",
                urls: urlsToCache,
              },
            }).then(afterAddToCache);
            useOfflineModeState.setState({ playedUrls: new Set() });
          }
        }
      });
    }
  }

  // Detecta se a conexão de internet está online ou offline
  const [monitoringConnection, setMonitoringConnection] = useState(false);
  useEffect(() => {
    if (monitoringConnection) {
      const interval = setInterval(() => {
        if (!useOfflineModeState.getState().enableConnectionMonitor) {
          // console.log("Connection Monitor is disabled");
          return;
        }
        checkConnection(
          () => {
            renewPlayedUrls(radio.folder, afterRemoveFromCache); // REMOVE_FROM_CACHE
            if (!useOfflineModeState.getState().isOnline) {
              useOfflineModeState.setState({ isOnline: true });
            }
          },
          () => {
            if (useOfflineModeState.getState().isOnline) {
              useOfflineModeState.setState({ isOnline: false });
            }
          },
        );
      }, 5_000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoringConnection, radio.folder]);

  // Inicia a música offline, se aplicável
  const { isOnline, error, color, cachedUrls } = useOfflineModeState();
  const currentTrackId = usePlayerState((state) => state.currentTrackId);
  const isOfflinePlaying = currentTrackId === "offline";
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!isOnline && !isOfflinePlaying && cachedUrls.size > 0) {
      enterOfflineMode(queryClient);
    }
  }, [isOnline, isOfflinePlaying, cachedUrls.size, queryClient]);

  let text = "";
  switch (color) {
    case "red":
      text = error || "Instalando Modo Offline";
      break;
    case "yellow":
      text = error || "Preparando Modo Offline";
      break;
    case "green":
      text = isOfflinePlaying
        ? "Você está no Modo Offline"
        : "Modo Offline Disponível";
      break;
  }

  const offlineAudiosQuery = useOfflineAudiosQuery(radio);

  function afterAddToCache(eventData: MessageFromServiceWorker) {
    if (eventData.type !== "RESPONSE_ADD_TO_CACHE") return;

    const cachedUrls = new Set(eventData.payload.cachedUrls);
    useOfflineModeState.setState({ cachedUrls });

    if (cachedUrls.size >= 25) {
      useOfflineModeState.setState({
        color: "green",
        error: "",
      });
      setMonitoringConnection(true);
    } else if (cachedUrls.size > 0) {
      useOfflineModeState.setState({
        color: "yellow",
        error: "Modo Offline Parcialmente Disponível",
      });
      setMonitoringConnection(true);
    } else {
      useOfflineModeState.setState({
        color: "red",
        error: "Modo Offline Indisponível (B)",
      });
    }
  }

  function onPong(eventData: MessageFromServiceWorker) {
    if (eventData.type !== "PONG") return;

    exchangeMessage({
      idRadio: radio.folder,
      type: "PRECACHE",
    }).then(() => {
      console.log("[PRECACHE] Precaching complete.");
    });

    console.log("Received PONG from service worker");
    useOfflineModeState.setState({ color: "yellow" });
    checkConnection(
      () => {
        // online
        console.log("Online; asking Service Worker to add to cache");
        offlineAudiosQuery.refetch().then(({ data }) => {
          if (data?.cacheableAudioLinks) {
            const urlsToCache = data.cacheableAudioLinks.slice(0, 25);
            exchangeMessage({
              idRadio: radio.folder,
              type: "ADD_TO_CACHE",
              payload: {
                cacheName: "offlineAudios",
                urls: urlsToCache,
              },
            }).then(afterAddToCache);
          }
        });
      },
      () => {
        // offline
        console.log("Offline; asking Service Worker what is cached");
        exchangeMessage({
          idRadio: radio.folder,
          type: "ADD_TO_CACHE",
          payload: {
            cacheName: "offlineAudios",
            urls: [],
          },
        }).then(afterAddToCache);
      },
    );
  }

  useEffect(() => {
    if (color !== "red") return;
    const interval = setInterval(() => {
      if (useOfflineModeState.getState().color === "red") {
        alwaysResolve(getServiceWorker()).then(({ success }) => {
          if (success) {
            console.log("Sending PING to service worker");
            exchangeMessage({
              idRadio: radio.folder,
              type: "PING",
            }).then(onPong);
          }
        });
      }
    }, 1_000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <div className="OfflineModeIndicator-component">
      <span className="bolinha" style={{ color }}>
        ⬤
      </span>
      <span className="texto">{text}</span>
      {devMode && <TestButtons />}
    </div>
  );
}
