import { Button } from "@src/components/Button";
import { FormGroup } from "@src/components/FormGroup";
import { Input } from "@src/components/Input";

import { useStateWithQueryString } from "@src/hooks/useStateWithQueryString";

import { browserName } from "@src/utils/browser";
import { downloadString } from "@src/utils/downloadString";
import { onlyDigits } from "@src/utils/onlyDigits";

import { generateXml } from "./generateXml";
import "./style.less";

export function Autoplay() {
  const [idRadio, setIdRadio] = useStateWithQueryString("", "idRadio");
  const [cnpj, setCnpj] = useStateWithQueryString("", "cnpj");
  const [password, setPassword] = useStateWithQueryString("", "password");
  const [pwaName, setPwaName] = useStateWithQueryString("", "pwaName");
  const [appId, setAppId] = useStateWithQueryString("", "appId");
  const [whoAmI, setWhoAmI] = useStateWithQueryString("", "whoAmI");

  function submitForm() {
    const xml = generateXml(idRadio, cnpj, password, pwaName, appId, whoAmI);
    downloadString(`${pwaName}.xml`, xml);
  }

  if (browserName !== "Chrome") {
    return (
      <div className="Autoplay-page">
        <small>Abra no Google Chrome</small>
      </div>
    );
  }

  return (
    <div className="Autoplay-page">
      <form
        className="card"
        onSubmit={(event) => {
          event.preventDefault();
          submitForm();
        }}
      >
        <fieldset>
          <FormGroup>
            <label htmlFor="idRadio">Nome da rádio*</label>
            <Input
              id="idRadio"
              type="text"
              value={idRadio}
              onChange={(event) => {
                setIdRadio(event.currentTarget.value);
              }}
              required
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="cnpj">CNPJ</label>
            <Input
              id="cnpj"
              type="text"
              value={cnpj}
              onChange={(event) => {
                setCnpj(onlyDigits(event.currentTarget.value));
              }}
              autoComplete="username"
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="senha">Senha</label>
            <Input
              id="senha"
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.currentTarget.value);
              }}
              autoComplete="current-password"
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="pwaName">Nome do PWA*</label>
            <small>
              Localize em{" "}
              <a href="chrome://apps" target="_blank">
                chrome://apps
              </a>
            </small>
            <Input
              id="pwaName"
              type="text"
              value={pwaName}
              placeholder="PWA FabriccaPro"
              onChange={(event) => {
                setPwaName(event.currentTarget.value);
              }}
              required
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="appId">App ID*</label>
            <small>
              Localize em{" "}
              <a href="chrome://apps" target="_blank">
                chrome://apps
              </a>
            </small>
            <Input
              id="appId"
              type="text"
              value={appId}
              placeholder="amghcfbniidaolnmpbcjaofjbjgappdd"
              onChange={(event) => {
                setAppId(event.currentTarget.value);
              }}
              required
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="deviceName">Who Am I (Windows)*</label>
            <small>Output do comando `whoami` no cmd.exe</small>
            <Input
              id="deviceName"
              type="text"
              value={whoAmI}
              placeholder="my_pc\my_user"
              onChange={(event) => {
                setWhoAmI(event.currentTarget.value);
              }}
              required
            />
          </FormGroup>

          <Button type="submit">Gerar Tarefa Agendada</Button>
        </fieldset>
      </form>
    </div>
  );
}
