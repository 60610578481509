import { useMeuPlayState } from "@src/states/meuplay";
import {
  getInitialOfflineModeState,
  useOfflineModeState,
} from "@src/states/offline_mode";
import { getInitialPlayerState, usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";

export function logout() {
  player.removeAllTracks().then(() => {
    const volume = useMeuPlayState.getState().volume;
    const initialMeuPlayState = useMeuPlayState.getInitialState();
    useMeuPlayState.setState(
      {
        ...initialMeuPlayState,
        volume,
      },
      true,
    );

    useOfflineModeState.setState(getInitialOfflineModeState(), true);
    usePlayerState.setState(getInitialPlayerState(), true);
  });
}
